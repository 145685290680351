import { useEffect, useState } from 'react';
import { getMutualGuilds, getProject, postNewProject, postNewTeam } from '../api';
import { PartialGuild, Project, ProjectDetails, Team, TeamDetails } from '../types';

export function useFetchProject(id: number) {

    const [project, setProject] = useState<Project>();
    const [loadingProject, setLoading] = useState(false);
    const [error, setError] = useState();
    const pp: Project = JSON.parse(JSON.stringify(localStorage.getItem('project')))
    useEffect(() => {
        setLoading(true);
        getProject(id)
            .then(({ data }) => {
                setProject(data);
            })
            .catch((err) => {
                setError(err);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    return { project, loadingProject, error };
}
