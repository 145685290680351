import { FaBars } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

export const MainButton = styled.div`
  display: flex;
  width: 350px;
  align-items: center;
  justify-content: space-between;
  background-color: #333333;
  padding: 4px 50px;
  /* border-color: #70e1c8; */
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid #000;
  margin: 10px 0;
  box-shadow: 0px 1px 5px 0px #00000018;
`;

export const TextButton = styled(MainButton)`
  padding: 18px 28px;
  width: 100%;
  background-color: #272727;
`;

export const HomePageStyle = styled.div`
  height: 100%;
  padding: 100px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const GuildMenuItemStyle = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin:5px;
  row-gap: 5px;
  width: auto;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.50);
  align-items: center;
  margin-top: 20px;
  padding: 5px;
  background-color: #8b8a8a;
  border-radius: 10px;
`;

export const DocumentContainerStyle = styled.div`display:grid;
  position: relative;
  grid-template-columns:2fr 2fr;
  column-gap: 10px;

`;

export const Container = styled.div`
  width: 1200px;
  margin: 0 auto;
`;

export const DocumentContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 2fr 8fr;
  grid-template-rows: 1fr 13fr;
  column-gap: 30px;
  height: 100%;
  
  row-gap: 5px;
`;
export const ProjectInfo = styled.div`
  background: #6c6c6c;
  height: max-content;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.50);
  display: grid;
  grid-template-rows: max-content

`;

export const ProjectContainer = styled.div`
  width: 100%;
  margin: 0 auto;
`;

export const CategoryContainerStyle = styled.div`
  display: grid;
  grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) );
  grid-template-rows: auto;
  row-gap: 5px;
  column-gap: 5px;
`;
export const DocCanvasStyle = styled.div` 
padding: 5px;
overflow-x: hidden;
  max-height: 90vh;

  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 3px;
  background: #333333;
  /* overflow-y:scroll; */
  border-radius: 5px;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.50);
  border-radius: 10px;
`;

export const AppBarStyle = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 35px;
  box-sizing: border-box;
  border-bottom: 1px solid #c9c9c921;
`;

export const Title = styled.p`
  font-size: 22px;
`;

type FlexProps = Partial<{
  alignItems: string;
  justifyContent: string;
  flexDirection: string;
}>;
export const Flex = styled.div<FlexProps>`
  display: flex;
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};
  flex-direction: ${({ flexDirection }) => flexDirection};
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 10px;
`;

export const InputField = styled.input`
  padding: 14px 16px;
  box-sizing: border-box;
  font-size: 16px;
  color: #fff;
  font-family: 'DM Sans';
  background-color: #272727;
  border-radius: 5px;
  border: 1px solid #3f3f3f;
  outline: none;
  width: 100%;
  :focus {
    outline: 1px solid #ffffff5a;
  }
`;

export const TextArea = styled.textarea`
  padding: 14px 16px;
  box-sizing: border-box;
  font-size: 16px;
  color: #fff;
  font-family: 'DM Sans';
  background-color: #272727;
  border-radius: 5px;
  border: 1px solid #3f3f3f;
  outline: none;
  width: 100%;
  resize: none;
  :focus {
    outline: 1px solid #ffffff5a;
  }
`;

type ButtonProps = {
  variant: 'primary' | 'secondary';
};

export const Button = styled.button<ButtonProps>`
  border-radius: 5px;
  outline: none;
  border: none;
  font-size: 16px;
  color: #000000;
  width: 1200px;
  font-family: 'DM Sans';
  cursor: pointer;
  ${({ variant }) =>
    variant === 'primary' &&
    css`
    `}
  ${({ variant }) =>
    variant === 'secondary' &&
    css`
    `}
`;

export const Page = styled.div`
  padding: 20px;
  height: 86.5vh;
  overflow-y: scroll;
`;
export const NavBarStyle = styled.div`
  background-color: #04AA6D;
  height: 64px;
  width: 100%;
`;

export const Select = styled.select`
  width: 100%;
  padding: 10px;
  font-family: 'DM Sans';
  font-size: 18px;
  background-color: inherit;
  padding: 12px 16px;
  color: #fff;
  border: 1px solid #3f3f3f;
  border-radius: 5px;
  & > option {
    background-color: #fff;
  }
`;

export const Overlay = styled.div`
  height: 100%;
  width: 100%;
  background-color: #0000006c;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
`;

export const UserBanCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ffffff1f;
  box-sizing: border-box;
  padding: 18px;
  border-radius: 4px;
`;

type ContextMenuContainerProps = {
  top: number;
  left: number;
};

export const ContextMenuContainer = styled.div<ContextMenuContainerProps>`
  position: absolute;
  background-color: #353535;
  border-radius: 3px;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.15);
  height: auto;
  width: 200px;
  box-sizing: border-box;
  ${({ top, left }) => css`
    top: ${top}px;
    left: ${left}px;
  `}
  ul {
    list-style-type: none;
    margin: 0;
    padding: 8px;
    box-sizing: border-box;
  }

  ul li {
    padding: 10px 14px;
    border-radius: 3px;
  }

  ul li:hover {
    background-color: #444444;
    cursor: pointer;
  }
`;

export const Nav = styled.nav`
    background: #333333;
    height: 85px;
    display: flex;
    justify-content: space-between;
    z-index: 12;
    /* Third Nav */
    /* justify-content: flex-start; */
`;

export const NavLink = styled(Link)`
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    &.active {
        color: #000000;
    }
`;

export const Bars = styled(FaBars)`
    display: none;
    color: #808080;
    @media screen and (max-width: 768px) {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 75%);
        font-size: 1.8rem;
        cursor: pointer;
    }
`;

export const NavMenu = styled.div`
    display: flex;
    align-items: center;
    margin-right: -100px;
    flex-direction: row;
    margin-left: 34px;
    column-gap: 10px;
    /* Second Nav */
    /* margin-right: 24px; */
    /* Third Nav */
    /* width: 100vw;
  white-space: nowrap; */
    @media screen and (max-width: 768px) {
        display: none;
    }
`;

export const NavBtn = styled.nav`
    display: flex;
    align-items: center;
    margin-right: 24px;
    
    /* Third Nav */
    /* justify-content: flex-end;
  width: 100vw; */
    @media screen and (max-width: 768px) {
        display: none;
    }
`;

export const NavBtnLink = styled(Link)`
    border-radius: 4px;
    background: #808080;
    padding: 10px 22px;
    color: #000000;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    /* Second Nav */
    margin-left: 24px;
    &:hover {
        transition: all 0.2s ease-in-out;
        background: #fff;
        color: #808080;
    }
`;


export const ContainerStyle = styled.div`
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.50);
  border-radius: 10px;
  padding: 5px;
  background: #fff;
`;


export const EditionPanelStyle = styled.div`
  margin-top: 1%;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.50);
  border-radius: 10px;
  padding: 5px;
  background: #333333;
`;

export const EditionPanelToggledStyle = styled.div`
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-columns: 2fr 1fr;
  column-gap: 10px;
`;


// Main container
export const CustomFileUpload = styled.div`
  height: 200px;
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border: 2px dashed #cacaca;
  background-color: rgba(255, 255, 255, 1);
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0px 48px 35px -48px rgba(0,0,0,0.1);
`;

// Icon container
export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 80px;
    fill: rgba(75, 85, 99, 1);
  }
`;

export const Upload = styled.input`
  display: block;
  height: 20px;
  width: 40px;
  /* position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; */
  /* opacity: 0; */
  cursor: pointer;
`;

// Text container
export const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-weight: 400;
    color: rgba(75, 85, 99, 1);
  }
`;

// Hidden input
const HiddenInput = styled.input`
  display: none;
`;


export const SubmitButtonStyle = styled.button`
  background-color: #6c6c6c;
  padding: 5px 15px;
  border-radius: 5px;
  outline: 0;
  color:#fff;
  border: 0; 
  text-transform: uppercase;
  margin: 10px 0px;
  cursor: pointer;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.50);
  transition: ease background-color 250ms;

`;

export const AddEntityButtonStyle = styled.button`
  background-color: #70e1c8;
  padding: 5px 15px;
  border-radius: 5px;
  outline: 0;
  border: 0; 
  text-transform: uppercase;
  margin: 10px 0px;
  cursor: pointer;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.50);
  transition: ease background-color 250ms;
  &:hover {
    background-color: #70e1c8;
    scale: 105%;
  }
  &:disabled {
    cursor: default;
    opacity: 0.7;
  }
`;

export const CatButtonStyle = styled.button`
    background-color: #fff;
  padding: 5px 15px;
  border-radius: 5px;
  outline: 0;
  border: 0; 
  text-transform: uppercase;
  margin: 10px 0px;
  cursor: pointer;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.50);
  transition: ease background-color 250ms;
  background: #333333;
  color: #70e1c8;
  &:hover {
    background-color: #70e1c8;
  }
  &:disabled {
    cursor: default;
    opacity: 0.7;
  }
`;

export const BlockHeading = styled.div`
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.50);
  border-radius: 10px;
  padding: 5px;
  /* display: flex;
  align-items: center;
  justify-content: space-between; */
  background: #70e1c8;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 6px;
`;


