import { ChangeEvent, useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BarLoader, MoonLoader } from 'react-spinners';
import { AddEntityButtonStyle, BlockHeading, DocumentContainer, ContainerStyle, Flex, Page, ProjectInfo, SubmitButtonStyle } from '../utils/styles/index.styles';
import { DiscordUserType, Doc, PartialGuildChannel, Project } from '../utils/types';
import { useFetchDocuments } from '../utils/hooks/useFetchDocuments';
import { DocMenuItem } from '../components/document/documentItem/DocMenuItem';
import { DocContext } from '../utils/contexts/DocContext';
import { FileInput } from '../components/FileInput';
import { CategoryContext } from '../utils/contexts/CategoryContext';
import { NavBar } from '../components/menuItems/NavBar';
import { Modal } from '../components/document/createDocument/Modal';
import { useFetchUser } from '../utils/hooks/useFetchUser';

import RATEIT2 from '../assets/RATEIT.svg'
import { ProjectContext } from '../utils/contexts/ProjectContext';
import { NewDocModal } from '../components/document/createDocument/NewDocumentModal';
import { useFetchProject } from '../utils/hooks/useFetchProject';
import { Spinner } from '../components/Spinner';
import { AboutDocumentModal } from '../components/document/createDocument/AboutDocumentModal';




export const DocumentListPage = () => {
  const { user } = useFetchUser()

  const { doc, updateDoc } = useContext(DocContext)
  const { id } = useParams();

  const { project, loadingProject } = useFetchProject(parseInt(id!));
  // const { project, updateProject } = useContext(ProjectContext);
  // console.log(projectt)
  // updateProject(projectt)

  const navigate = useNavigate();
  const [selectedUsers, setSelectedUsers] = useState<DiscordUserType[]>([]);
  const handleUserChange = (user: DiscordUserType, event: ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = event.target;

    setSelectedUsers((prev) =>
      checked ? [...prev, user] :
        prev.filter((competence) => competence.id !== (user.id))
    );
    // console.log(selectedUsers)
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false);

  const [curdoc, setCurdoc] = useState<Doc>();
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const toggleAboutModal = (doc: Doc) => {
    setCurdoc(doc);
    setIsAboutModalOpen(!isAboutModalOpen);
  }

  const { documents, setLoading, loading, handleUpload } = useFetchDocuments();

  const category = JSON.parse(localStorage.getItem('category')!);
  const handleClick = (doc: Doc) => {
    updateDoc(doc);
    navigate(`/document`);
  };

  return (
    <>
      <Page style={{ overflow: 'scroll', height: '87vh' }}>
        {/* <Modal isOpen={isModalOpen} onClose={toggleModal} getParticipantsWallets={getCategoryParticipantsWallets} document={doc!} handleUpload={handleUpload} /> */}

        {project && <NewDocModal project={project} isOpen={isModalOpen} onClose={toggleModal} onFileUpload={handleUpload}
        />}
        {project && curdoc && <AboutDocumentModal project={project} isOpen={isAboutModalOpen} onClose={() => (toggleAboutModal(curdoc!))} doc={curdoc!} onFileUpload={handleUpload}
        />}
        {/* <ContainerStyle style={{ width: 'maxContent', margin: '0 auto' }}> */}
        <DocumentContainer >
          {/* <BlockHeading style={{ display: 'flex', justifyContent: 'space-between' }}> */}
          <h1 style={{ margin: '0px', height: 'max-content' }}>PROJECT</h1>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h2 style={{ margin: '0px', height: 'max-content' }}>Project documents:</h2>
            <SubmitButtonStyle onClick={toggleModal} style={{ display: 'flex', color: '#ffffff', alignItems: 'center', height: '50px', background: "#512da8" }}>
              <b>Create New Document</b>
            </SubmitButtonStyle>
          </div>
          {/* @ts-ignore */}
          {loadingProject ? (<Spinner children={<BarLoader color="white" />} />) : (<ProjectInfo >

            <ContainerStyle style={{ margin: '10px', height: 'auto', background: '#bbbbbb' }}>
              <img src={project?.photo?.url} style={{ width: '100%', borderRadius: '10px' }}></img>
            </ContainerStyle>
            <ContainerStyle style={{ margin: '10px', height: 'auto', maxWidth: '30vw', background: '#bbbbbb' }}>
              <h4 style={{ margin: '5px' }}>Name:</h4>
              <pre style={{ fontFamily: 'DM Sans', marginLeft: '5px', width: '100%', marginTop: 0, maxHeight: '100px', overflowX: 'auto', color: 'black', whiteSpace: 'wrap' }}>
                <i>{project?.name}
                </i>
              </pre>
              <h4 style={{ margin: '5px' }}>Description: </h4>
              <pre style={{ fontFamily: 'DM Sans', marginLeft: '5px', width: '100%', marginTop: 0, maxHeight: '100px', overflowX: 'auto', color: 'black', whiteSpace: 'wrap' }}>
                <i>{project?.description}</i>

              </pre>


              {/* <h4 style={{ margin: '5px' }}>System Rank: </h4><i style={{ marginLeft: '5px', color: 'black' }}>{project?.sysRank}</i> */}
              <h4 style={{ margin: '5px' }}>Competences: </h4>
              {(project?.competences?.length && project?.competences?.length > 0) ? (<>

                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', rowGap: '5px', maxHeight: '25vh', overflow: 'scroll', columnGap: '4px' }}>
                  {project?.competences?.map((competence) => (
                    <div style={{ background: "#fff", alignItems: 'center', overflowY: 'hidden', overflowX: 'scroll', borderRadius: "5px" }}>
                      <label key={competence.id} style={{ height: '100%', marginRight: '3px', marginLeft: '3px', margin: '0px', whiteSpace: "nowrap", display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                        #{competence.name}
                      </label>
                    </div>
                  ))}
                </div>
              </>) : (
                <>
                  <div style={{}}>
                    <label style={{ height: '100%', margin: '0px', marginRight: '3px', marginLeft: '3px', whiteSpace: "nowrap", display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                      <i>No competences in this project.</i>
                    </label>
                  </div>
                </>
              )
              }
              <h4 style={{ margin: '5px' }}>Project Members: </h4>
              {(project?.editors?.length && project?.editors?.length > 0) ? (<>
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', rowGap: '5px', maxHeight: '25vh', overflow: 'scroll', columnGap: '4px' }}>
                  {project?.editors?.map((editor: DiscordUserType) => (
                    <div style={{ background: "#fff", alignItems: 'center', overflowY: 'hidden', overflowX: 'scroll', borderRadius: "5px" }}>
                      <label key={editor.id} style={{ height: '100%', margin: '0px', marginRight: '3px', marginLeft: '3px', whiteSpace: "nowrap", display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                        #{editor.username}
                      </label>
                    </div>
                  ))}

                </div>
              </>) : (
                <>
                  <div style={{}}>
                    <label style={{ height: '100%', margin: '0px', marginRight: '3px', marginLeft: '3px', whiteSpace: "nowrap", display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                      <i>No editors in this project.</i>
                    </label>
                  </div>
                </>
              )
              }
            </ContainerStyle>
          </ProjectInfo>)}
          <div style={{ marginTop: '0px' }}>

            <div style={{ display: 'flex', maxHeight: '71vh', overflowY: 'scroll', flexDirection: 'column' }}>
              {/* {publicKey === null &&
                <span style={{}}><b style={{ color: "red" }}>ALERT: </b>Connect your wallet to create document.</span>
              } */}
              {/* {!loading && (
                <div style={{ height: 'max-content' }}>
                  <Container style={{ marginTop: 40, height: 35, width: '50%' }}>
                    <FileInput doc={doc!} onFileUpload={handleUpload} />
                  </Container>
                </div>
              )} */}

              {loading && (<>
                <span style={{ marginTop: '20px' }}>Loading document...</span>
                <Flex justifyContent="center" style={{ marginTop: '50px', marginBottom: '50px' }}>
                  {/* @ts-ignore */}

                  <BarLoader color="black" />
                </Flex>
              </>
              )}

              {/* @ts-ignore */}

              {loadingProject ? (<Spinner children={<BarLoader color="white" />} />) : (<>{
                (documents && documents.length > 0) ? (documents?.map((doc) => (
                  <div key={"document-" + doc.id} style={{ marginBottom: '10px' }} onClick={() => handleClick(doc)}>
                    <DocMenuItem doc={doc} toggleAbout={toggleAboutModal} />
                  </div>
                )
                )) : (<label style={{ marginLeft: '10px' }}><b style={{ color: 'red' }}>ALERT: </b>You have no available documents, create one...</label>)

              }</>)}

            </div>
            <div style={{ height: '10vh', padding: '5px', background: "#ffffff" }}>
              {/* <Wallet props={{}} /> */}
              {/* <ContainerStyle style={{ height: '10vh', display: 'flex', background: "#6c6c6c" }}> */}
              {/*  */}
              {/* {publicKey === null ? <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems:'center', marginRight: '20px' }}>
            <label style={{ marginTop: '20px', opacity: 5 }}>Connect your wallet to add doc:</label>
            </div>
            : ( */}
              {/* {publicKey != null ?
                (<AddEntityButtonStyle style={{ margin: '20px', marginLeft: '120px', height: 'auto', background: '#6c6c6c', color: '#fff', borderRadius: '5px', padding: '10px' }}
                  onClick={toggleModal}
                >add new document <b>with</b> deposit</AddEntityButtonStyle>) : (
                  <WalletModalProvider >
                    <BaseWalletMultiButton style={{
                      background: '#6c6c6c',
                      margin: '20px', marginLeft: '120px', borderRadius: '5px',
                      fontSize: '13px', lineHeight: '13px'
                    }} labels={LABELS} />
                  </WalletModalProvider>
                )} */}
              {/* )} */}
              {/* {publicKey === null ? <label>Connect your wallet to add doc</label> : (<button style={{ background: 'yellow', margin: '0px', borderRadius: '5px', padding: '5px', height: '40px' }}
              onClick={toggleModal}
            >add doc</button>)} */}

              {/* </ContainerStyle > */}
            </div >
          </div>
        </DocumentContainer >

      </Page >

    </>
  );
};