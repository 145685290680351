import { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { BarLoader } from 'react-spinners';
import { AppBar } from './components/AppBar';
import { Spinner } from './components/Spinner';
import { LoginPage } from './pages/LoginPage';
import { MenuPage } from './pages/TeamPage';


import { useFetchUser } from './utils/hooks/useFetchUser';
import { Doc, PartialGuild, PartialGuildChannel, Project } from './utils/types';
import { DocumentListPage } from './pages/ProjectPage';
import { DocumentPage } from './pages/DocumentPage';
import { DocContext } from './utils/contexts/DocContext';
import { NavBarStyle, ProjectContainer } from './utils/styles/index.styles';
import { NavBar } from './components/menuItems/NavBar';
import { AccountPage } from './pages/AccountPage';

import { FC, ReactNode, useMemo } from "react";

import { CategoryContext } from './utils/contexts/CategoryContext';

import { ProjectContext } from './utils/contexts/ProjectContext';


function App() {


  const { user, loading, error } = useFetchUser();

  const [category, setCategory] = useState<PartialGuildChannel>();
  const updateCategory = (category: PartialGuildChannel) => (setCategory(category), localStorage.setItem('category', JSON.stringify(category)));

  const [doc, setDoc] = useState<Doc>();
  const updateDoc = (doc: Doc) => (setDoc(doc), localStorage.setItem('doc', JSON.stringify(doc)));

  const [project, setProject] = useState<Project>();
  const updateProject = (project: Project) => (setProject(project), localStorage.setItem('project', JSON.stringify(project)));


  // @ts-ignore
  if (loading) return <Spinner children={<BarLoader color="white" />} />;
  return (

    <ProjectContext.Provider value={{ project, updateProject }}>
      <CategoryContext.Provider value={{ category, updateCategory }}>
        <DocContext.Provider value={{ doc, updateDoc }}>
          <NavBar />
          {user && !error ? (
            <>
              <Routes>
                <Route path="/dashboard/*" element={<AppBar />} />
              </Routes>
              <Routes>
                <Route path="/" element={<LoginPage />} />
                <Route path="/menu" element={<MenuPage />} />
                <Route path="/account" element={<AccountPage />} />
                <Route path="/documents/:id" element={<DocumentListPage />} />
                <Route path="/document" element={<DocumentPage />} />
              </Routes>
            </>
          ) : (
            <Routes>
              <Route path="/" element={<LoginPage />} />
              <Route path="*" element={<LoginPage />} />
            </Routes>
          )}
        </DocContext.Provider>
      </CategoryContext.Provider>
    </ProjectContext.Provider>
  );
}

export default App;
