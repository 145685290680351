import { FaDiscord, FaQuestionCircle } from 'react-icons/fa';
import { HomePageStyle, MainButton } from '../utils/styles/index.styles';
import { useFetchUser } from '../utils/hooks/useFetchUser';
import RATEIT2 from '../assets/RATEIT-2.png'
export const LoginPage = () => {
  const { user } = useFetchUser();
  if (user != undefined) {
    window.location.href = 'https://rateit.expert/menu';
    // window.location.href = 'http://localhost:3000/menu';

  }
  const redirect = () => {
    window.location.href = 'https://rateit.expert/api/v1/auth/login';
    // 
    // window.location.href = 'http://localhost:3001/v1/auth/login';
  };

  return (
    <HomePageStyle>
      <div></div>
      <div>
        <MainButton style={{ cursor: 'pointer' }} onClick={redirect}>
          <FaDiscord size={45} color="#5865F2" />
          <p style={{ textDecoration: 'none', fontSize: '18px', color: '#fff' }}>Login with Discord</p>
        </MainButton>
        {/* <a style={{ textDecoration: 'none', color: 'black' }} href="https://discord.com/oauth2/authorize?client_id=1206608627184439337&permissions=0&scope=bot%20applications.commands" target="_blank">
          <MainButton>
            <FaDiscord size={45} />
            <p style={{ fontSize: '18px', color: '#fff' }}>Add bot to server</p>
          </MainButton>
        </a> */}
      </div>
      <div
        style={{
          display: 'flex',
          width: '400px',
          justifyContent: 'space-between',
        }}
      >
        <span>Privacy Policy</span>
        <span>Terms of Service</span>
        <span>Contact Us</span>
      </div>
      {/* <img src={RATEIT2} width={'100%'}/> */}
    </HomePageStyle>
  );
};
