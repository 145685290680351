import { useEffect, useState, useRef, useContext } from 'react';
import axios from 'axios';
import { getDocumentParagraphs, postEditionGrade, postFinalisation, postNewParagraph, postParagraphGrade } from '../api';
import { Competence, Doc, Paragraph, ParagraphDetails, ParagraphEdition, ParagraphEditionDetails, User } from '../types';
import { DocContext } from '../contexts/DocContext';
import FileSaver from 'file-saver';

interface FetchDocumentParagraphsResult {
    paragraphs: Paragraph[] | undefined;
    loading: boolean;
    error: any;
    isFinalised: boolean;
    handleNewParagraph: (content: string, paragraph: Paragraph, user: User) => void;
    handleGradeEd: (edition: ParagraphEdition, user: User, grade: number, competence: Competence) => void;
    update: (doc: Doc) => void;
    handleFinalization: (documentId: number) => void;
    downloadDoc: (paragraphs: Paragraph[]) => void;
    currentPage: number,
    setCurrentPage: (int: number) => void;
}

export function useFetchDocumentParagraphs(document: Doc, page: number): FetchDocumentParagraphsResult {
    const [paragraphs, setParagraphs] = useState<Paragraph[]>();
    const [isFinalised, setIsFinalised] = useState<boolean>(document.isFinalised);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>();
    const [currentPage, setCurrentPage] = useState<number>(page);

    const { updateDoc } = useContext(DocContext)


    const downloadDoc = (paragraphs: Paragraph[]) => {
        let buffer = '';
        paragraphs.map((par) => {
            if (par.isApproved) {
                buffer += par.content.replace('/(^[ \t]*\n)/gm', '')

            }
        })
        FileSaver.saveAs(new Blob([buffer]), document.name);

    }

    const handleFinalization = (documentId: number) => {
        postFinalisation(documentId).then(doc => {
            updateDoc(doc);
            update()
            // window.location.reload()
        }
        )
    }

    const update = () => {
        getDocumentParagraphs(document.id, page)
            .then(response => {
                {
                    setParagraphs(response.data);
                }
            })
    }

    const handleGradeEd = (edition: ParagraphEdition, user: User, grade: number, competence: Competence) => {
        postEditionGrade(grade, user, edition, competence).then(paragraph => {
            update()
        })
    }

    const handleNewParagraph = (content: string, paragraph: Paragraph, user: User) => {
        try {
            const paragraphDetails: ParagraphDetails = {
                creatorId: user.discordId,
                content: content,
                sysRank: 0.00001,
                orderId: paragraph.orderId + 1,
                document: document,
                page: paragraph.page
            }
            postNewParagraph(paragraphDetails).then(paragraphs => (setParagraphs(paragraphs)));
        } catch (e) {
        }
    };

    useEffect(() => {
        setLoading(true);
        getDocumentParagraphs(document.id, currentPage)
            .then(response => {
                {
                    setParagraphs(response.data);
                }
            })
            .catch(err => {
                setError(err);
            })
            .finally(() => {
                {
                    setLoading(false);
                }
            });


    }, [document.id, currentPage]);

    return { paragraphs, currentPage, setCurrentPage, loading, isFinalised, error, handleFinalization, handleNewParagraph, handleGradeEd, update, downloadDoc };
}
