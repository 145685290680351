import React, { ChangeEvent, FormEvent, useRef, useState } from 'react';
import './NewDocumentModal.css'; // Assume basic styling here
import { Competence, DiscordUserType, ProjectDetails, Team } from '../../utils/types';
import { SubmitButtonStyle } from '../../utils/styles/index.styles';
import PLUS from '../../assets/plus.png';
import { useFetchUser } from '../../utils/hooks/useFetchUser';


interface Props {
    team: Team
    isOpen: boolean,
    onClose: () => void,
    handleNewProject: any
}

interface User {
    name: string;
}

export const CreateProjectModal = ({ team, isOpen, onClose, handleNewProject }: Props) => {
    // console.log('team2 ' + team.id)

    const [name, setName] = useState<string | undefined>(undefined);
    const [description, setDescription] = useState<string | undefined>(undefined);
    const [users, setUsers] = useState<User[]>([]);
    const [photoPreview, setPhotoPreview] = useState<string | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [photo, setPhoto] = useState<File | null>(null);
    const handleInputChange = (index: number, event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        const newUsers = [...users];
        newUsers[index] = { ...newUsers[index], [name]: value };
        setUsers(newUsers);
    };
    const [error, setError] = useState<string | undefined>(undefined)

    // const handleAddUser = () => {
    //     setUsers([...users, { name: '', email: '' }]);
    // };

    const handleRemoveUser = (index: number) => {
        const newUsers = users.filter((_, i) => i !== index);
        setUsers(newUsers);
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            if (file) {
                setPhoto(file);
                const reader = new FileReader();
                reader.onloadend = () => {
                    setPhotoPreview(reader.result as string);
                };
                reader.readAsDataURL(file);
            } else {
                setPhotoPreview(null);
            }
        }
    };
    const competencesList = [
        'Competence 1',
        'Competence 2',
        'Competence 3',
        'Competence 4',
        'Competence 1',
        'Competence 2',
        'Competence 3',
        'Competence 4',
        'Competence 1',
        'Competence 2',
        'Competence 3',
        'Competence 4',
        'Competence 1',
        'Competence 2',
        'Competence 3',
        'Competence 4'
    ];
    const [selectedCompetences, setSelectedCompetences] = useState<Competence[]>([]);

    const handleCompetenceChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value, checked, name } = event.target;
        const comp: Competence = { id: parseInt(value), name: name };
        setSelectedCompetences((prev) =>
            checked ? [...prev, comp] :
                prev.filter((competence) => competence.id !== parseInt(value))
        );
        // console.log(selectedCompetences)
    };

    const [selectedUsers, setSelectedUsers] = useState<DiscordUserType[]>([]);
    const handleUserChange = (user: DiscordUserType, event: ChangeEvent<HTMLInputElement>) => {
        const { checked, name } = event.target;

        setSelectedUsers((prev) =>
            checked ? [...prev, user] :
                prev.filter((competence) => competence.id !== (user.id))
        );
        // console.log(selectedUsers)
    };

    const handleImageClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const deleteImage = () => {
        setPhotoPreview(null);
        setPhoto(null)
    };

    const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setName(value);

    }
    const hadnleDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = event.target;
        setDescription(value);
    }
    const { user } = useFetchUser()
    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        if (!name) {
            setError('Name cannot be empty!')
        } if (!description) {
            setError('Description cannot be empty!')
        } if (!(selectedCompetences.length > 0)) {
            setError('Competences cannot be empty!')
        }
        if (!(selectedUsers.length > 0)) {
            setError('Users cannot be empty!')
        }
        const project = {
            name: name,
            creatorId: user?.discordId,
            description: description,
            sysRank: 0,
            competences: selectedCompetences,
            editors: selectedUsers,
            photo: photo
        }
        // console.log(project)
        // console.log(team.id)
        // console.log(name)
        // console.log(description)
        // console.log(selectedCompetences.length)
        if (name && description && (selectedCompetences.length > 0) && (selectedUsers.length > 0)) {
            // console.log('huy')
            handleNewProject(project, team.id)
            onClose();
            setDescription(undefined);
            setName(undefined)
            setPhoto(null)
            setSelectedCompetences([])
            setSelectedUsers([])
            setError(undefined)
        }


        // console.log('Submitting form data:', finalFormData)

        // handleNewTeam(finalFormData);
        // Implement the upload functionality here
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay" onClick={() => {
            onClose();
            setDescription(undefined);
            setName(undefined)
            setPhoto(null)
            setSelectedCompetences([])
            setSelectedUsers([])
            setError(undefined)
        }}>
            <div className="modal-content" style={{ height: 'max-content', width: '80%' }} onClick={e => e.stopPropagation()}>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <label style={{ fontSize: '25px' }}><b>NEW PROJECT</b></label>
                    {error && <label style={{ color: 'red' }}>{error}</label>}
                </div>
                <form onSubmit={handleSubmit}>
                    <div style={{ height: '100%', width: '100%', display: 'grid', gridTemplateColumns: '2fr 5fr 5fr', columnGap: '10px' }}>
                        <div>
                            <div>
                                <b><label htmlFor="photo">Project Photo:</label></b>
                            </div>
                            <div style={{ position: "relative", width: "fit-content" }}>
                                {photoPreview && (
                                    <img
                                        style={{
                                            background: "#fff",
                                            borderRadius: "10px",
                                            position: "absolute",
                                            bottom: "10px",
                                            left: "10px",
                                            width: "25%",
                                            cursor: "pointer"
                                        }}
                                        onClick={(e) => { e.stopPropagation(); deleteImage(); }}
                                        src="https://cdn4.iconfinder.com/data/icons/unicons/88/objects-trashcan-512.png"
                                        alt="Delete"
                                        className="bin-icon"
                                    />
                                )}
                                <img
                                    alt="Click to upload"
                                    onClick={handleImageClick}
                                    style={{
                                        cursor: 'pointer',
                                        background: '#BBBBBB',
                                        borderRadius: '10px',
                                        display: "block"
                                    }}
                                    src={photoPreview ? photoPreview : "https://cdn-icons-png.flaticon.com/512/4211/4211763.png"}
                                    width="90%"
                                />
                            </div>
                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                            />
                        </div>
                        <div style={{ background: "#575757", borderRadius: '10px', padding: '10px' }}>
                            <div>
                                <div>
                                    <b><label htmlFor="name">Project Name:</label></b>
                                </div>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    placeholder='Your project name... (60 characters max)'
                                    maxLength={60}
                                    style={{ width: '100%', border: 'none', borderColor: 'black', borderRadius: '5px', height: '30px' }}
                                    onChange={handleNameChange}
                                />
                            </div>
                            <div style={{ marginTop: '10px', }}>
                                <div>
                                    <b><label htmlFor="description">Description:</label></b>
                                </div>
                                <textarea
                                    id="description"
                                    name="description"
                                    maxLength={500}
                                    placeholder='Your project description... (500 characters max)'
                                    style={{ fontFamily: "Arial", width: '100%', height: '7vh', resize: 'none', border: 'none', borderColor: 'black', borderRadius: '5px' }}
                                    onChange={hadnleDescriptionChange}
                                />
                            </div>
                        </div>
                        <div style={{ background: "#575757", borderRadius: '10px', padding: '10px' }}>
                            <div>
                                <div>
                                    <b><label htmlFor="competences">Competences:</label></b>
                                </div>
                                <div>
                                    <div>
                                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', maxHeight: '16vh', padding: '2px', rowGap: '3px', columnGap: '5px', overflowY: 'scroll' }}>
                                            {team.competences?.map((competence) => (
                                                <div style={{ background: "#bbb", alignItems: 'center', overflowY: 'hidden', overflowX: 'scroll', borderRadius: "5px" }}>
                                                    <label key={competence.id} style={{ height: '100%', margin: '0px', whiteSpace: "nowrap", display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                                        <input
                                                            type="checkbox"
                                                            value={competence.id}
                                                            name={competence.name}
                                                            onChange={handleCompetenceChange}
                                                        />
                                                        {competence.name}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div style={{ background: "#575757", borderRadius: '10px', padding: '10px', maxHeight: '40vh', marginTop: '20px' }}>
                        <label><b>Project Members:</b></label>
                        <div style={{ display: 'grid', overflowY: 'scroll', maxHeight: '30vh', gridTemplateColumns: '1fr 1fr 1fr', columnGap: '20px', width: '100%' }}>
                            {team.editors?.filter((editor) => editor.username != user?.username).map((user, index) => (
                                <div key={index} className="user-field" style={{ background: '#bbb', borderRadius: '5px', display: 'flex', padding: '2px', width: '100%', marginTop: '2px', alignItems: 'center', marginBottom: '10px' }}>
                                    <input
                                        type="checkbox"
                                        name="selectedUsers"
                                        value={user.id} // Assuming user has an 'id' field
                                        onChange={(event) => handleUserChange(user, event)} // Handle checkbox change
                                        style={{ marginRight: '10px', width: '20px', height: '20px' }}
                                    />
                                    <span style={{ flexGrow: 1 }}>#{user.username}</span> {/* Display user name */}

                                </div>
                            ))}
                        </div>

                        {/* <img width="50" onClick={handleAddUser} style={{ background: '#bbbbbb', borderRadius: '5px', cursor: 'pointer' }} src={PLUS} /> */}
                        {/* <button type="button" onClick={handleAddUser} style={{ padding: '10px', cursor: 'pointer' }}>Add User</button> */}
                    </div>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'right' }}>
                        <SubmitButtonStyle style={{ height: '30px', background: '#70e1c8' }} type="submit">Submit Project</SubmitButtonStyle>
                    </div>
                </form>
            </div >
        </div >
    );
};
