import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import './NewDocumentModal.css'; // Assume basic styling here
import { Competence, TeamDetails } from '../../utils/types';
import { SubmitButtonStyle } from '../../utils/styles/index.styles';
import PLUS from '../../assets/plus.png';
import { useFetchCompetnces } from '../../utils/hooks/useFetchCompetences';
import { useFetchUser } from '../../utils/hooks/useFetchUser';
interface Props {
    usernames: string[]
    isOpen: boolean,
    onClose: () => void,
    handleNewTeam: any
}

interface User {
    name: string;
}

export const CreateTeamModal = ({ usernames, isOpen, onClose, handleNewTeam }: Props) => {

    const [name, setName] = useState<string | undefined>(undefined);
    const [description, setDescription] = useState<string | undefined>(undefined);
    const [users, setUsers] = useState<User[]>([]);

    const [photoPreview, setPhotoPreview] = useState<string | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [photo, setPhoto] = useState<File | null>(null);

    const handleInputChange = (index: number, event: ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);

        addUser(index, event);
    };
    const addUser = (index: number, event: ChangeEvent<HTMLInputElement>) => {
        // console.log('addUser')
        const { name, value } = event.target;
        const newUsers = [...users];
        newUsers[index] = { ...newUsers[index], [name]: value };
        setUsers(newUsers);
    }

    const handleItemClick = (index: number, variant: string) => {
        setInputValue(variant);
        const newUsers = [...users];
        newUsers[index] = { ...newUsers[index], ["name"]: variant };
        setUsers(newUsers);


    };

    const [error, setError] = useState<string | undefined>(undefined)


    const [inputValue, setInputValue] = useState<string>('');
    const [filteredVariants, setFilteredVariants] = useState<string[]>([]);


    const handleAddUser = () => {
        setUsers([...users, { name: '' }]);

    };

    const handleRemoveUser = (index: number) => {
        const newUsers = users.filter((_, i) => i !== index);
        setUsers(newUsers);

    };


    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            if (file) {
                setPhoto(file);
                const reader = new FileReader();
                reader.onloadend = () => {
                    setPhotoPreview(reader.result as string);
                };
                reader.readAsDataURL(file);
            } else {
                setPhotoPreview(null);
            }
        }
    };
    useEffect(() => {
        setFilteredVariants(
            usernames.filter(variant =>
                variant.toLowerCase().startsWith(inputValue.toLowerCase()) && !users.map(user => user.name).includes(variant) && !(variant == user?.username)
            )
        );
    }, [inputValue, users]);


    const { competences: competencesList } = useFetchCompetnces();
    const [selectedCompetences, setSelectedCompetences] = useState<Competence[]>([]);

    const handleCompetenceChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value, checked, name } = event.target;
        const comp: Competence = { id: parseInt(value), name: name };  // Assign `name` correctly
        setSelectedCompetences((prev: Competence[]) =>
            checked
                ? [...prev, comp]
                : prev.filter((competence) => competence.id !== parseInt(value))
        );
    };
    const handleImageClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
    const deleteImage = () => {
        setPhotoPreview(null);
        setPhoto(null)
    };
    const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setName(value);
    }
    const hadnleDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = event.target;
        setDescription(value);
    }
    const { user } = useFetchUser();

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        if (!name) {
            // console.log('huy')
            setError('Name cannot be empty!')
        } if (!description) {
            setError('Description cannot be empty!')
        } if (!(selectedCompetences.length > 0)) {
            setError('Competences cannot be empty!')
        }
        const impostors = users.map(user => user.name).filter(username => !usernames.includes(username));
        if (impostors.length > 0) {
            setError(`Users with nacknames: ${impostors.map(imp => ` #${imp}`)} do not exist!`)
        }
        if (!(users.length > 0)) {
            setError('Users cannot be empty!')
        }
        // console.log('eeb' + error)
        if (name && description && (selectedCompetences.length > 0) && (users.length > 0)) {
            // console.log('ee ' + error)
            // console.log('name ' + name, ' desc ' + description, ' sel ' + selectedCompetences)
            const team = {
                name: name,
                creatorId: user?.discordId,
                description: description,
                sysRank: 0,
                competences: selectedCompetences,
                editors: users,
                photo: photo
            }
            handleNewTeam(team);
            onClose();
            setDescription(undefined);
            setName(undefined)
            setPhoto(null)
            setSelectedCompetences([]);
            setUsers([])
            setError(undefined)
        }


        // Implement the upload functionality here
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay" onClick={() => {
            onClose();
            setDescription(undefined);
            setName(undefined)
            setPhoto(null)
            setPhotoPreview(null)
            setSelectedCompetences([]);
            setUsers([])
            setError(undefined)

        }}
        >
            <div className="modal-content" style={{ height: 'max-content', width: '80%' }} onClick={e => e.stopPropagation()}>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <label style={{ fontSize: '25px' }}><b>NEW TEAM</b></label>
                    {error && <label style={{ maxWidth: '100%', overflowX: 'auto', whiteSpace: 'nowrap', color: 'red' }}>{error}</label>}
                </div>
                <form autoComplete="off" onSubmit={handleSubmit}>
                    <div style={{ height: '100%', width: '100%', display: 'grid', gridTemplateColumns: '2fr 5fr 5fr', columnGap: '10px' }}>
                        <div>
                            <div>
                                <b><label htmlFor="photo">Team Photo:</label></b>
                            </div>
                            <div style={{ position: "relative", width: "fit-content" }}>
                                {photoPreview && (
                                    <img
                                        style={{
                                            background: "#fff",
                                            borderRadius: "10px",
                                            position: "absolute",
                                            bottom: "10px",
                                            left: "10px",
                                            width: "25%",
                                            cursor: "pointer"
                                        }}
                                        onClick={(e) => { e.stopPropagation(); deleteImage(); }}
                                        src="https://cdn4.iconfinder.com/data/icons/unicons/88/objects-trashcan-512.png"
                                        alt="Delete"
                                        className="bin-icon"
                                    />
                                )}
                                <img
                                    alt="Click to upload"
                                    onClick={handleImageClick}
                                    style={{
                                        cursor: 'pointer',
                                        background: '#BBBBBB',
                                        borderRadius: '10px',
                                        display: "block"
                                    }}
                                    src={photoPreview ? photoPreview : "https://cdn-icons-png.flaticon.com/512/4211/4211763.png"}
                                    width="90%"
                                />
                            </div>
                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                            />
                        </div>
                        <div style={{ background: "#575757", borderRadius: '10px', padding: '10px' }}>
                            <div>
                                <div>
                                    <b><label htmlFor="name">Team Name:</label></b>
                                </div>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    placeholder='Your team name... (60 characters max)'
                                    maxLength={60}
                                    style={{ position: 'relative', width: '100%', border: 'none', borderColor: 'black', borderRadius: '5px', height: '30px' }}
                                    onChange={handleNameChange}
                                />
                                {/* /input> */}
                            </div>
                            <div style={{ marginTop: '10px', }}>
                                <div>
                                    <b><label htmlFor="description">Description:</label></b>
                                </div>

                                <textarea
                                    id="description"
                                    name="description"
                                    placeholder='Your team description... (500 characters max)'
                                    maxLength={500}
                                    style={{ fontFamily: "Arial", width: '100%', height: '7vh', resize: 'none', border: 'none', borderColor: 'black', borderRadius: '5px' }}
                                    onChange={hadnleDescriptionChange}
                                />
                            </div>
                        </div>
                        <div style={{ background: "#575757", borderRadius: '10px', padding: '10px' }}>
                            <div>
                                <div>
                                    <b><label htmlFor="competences">Competences:</label></b>
                                </div>

                                <div>
                                    <div>
                                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', maxHeight: '16vh', padding: '2px', rowGap: '3px', columnGap: '5px', overflowY: 'scroll' }}>
                                            {competencesList?.map((competence: Competence) => (
                                                <div style={{ background: "#bbb", alignItems: 'center', overflowY: 'hidden', overflowX: 'scroll', borderRadius: "5px" }}>
                                                    <label key={competence.id} style={{ height: '100%', margin: '0px', whiteSpace: "nowrap", display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                                        <input
                                                            type="checkbox"
                                                            value={competence.id.toString()}
                                                            name={competence.name}
                                                            onChange={handleCompetenceChange}
                                                        />
                                                        {competence.name}
                                                    </label>
                                                </div>
                                            ))}

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div style={{ background: "#575757", borderRadius: '10px', padding: '10px', maxHeight: '40vh', marginTop: '20px' }}>
                        <label><b>Team Members:</b></label>
                        <div style={{ display: 'grid', overflowY: 'scroll', maxHeight: '30vh', gridTemplateColumns: '1fr 1fr 1fr', columnGap: '20px', width: '100%' }}>
                            {users.map((user, index) => (
                                <div key={index} style={{ display: 'flex', padding: '2px', width: '100%', marginTop: '2px', alignItems: 'center', marginBottom: '10px' }}>
                                    <input
                                        type="text"
                                        name="name"
                                        list="list"
                                        placeholder={`Discord username.`}
                                        value={user.name}
                                        onChange={(event) => handleInputChange(index, event)}
                                        style={{ maxHeight: '100px', marginRight: '10px', width: '100%', height: '80%', padding: '5px', borderRadius: '5px', border: '1px solid #ccc' }}
                                    />
                                    <datalist id="list">
                                        {filteredVariants.map(d => <option key={d} value={d} />)}
                                    </datalist>

                                    <img
                                        style={{
                                            background: "#fff",
                                            borderRadius: "5px",
                                            width: "40px",
                                            cursor: "pointer"
                                        }}
                                        onClick={() => handleRemoveUser(index)}
                                        src="https://cdn4.iconfinder.com/data/icons/unicons/88/objects-trashcan-512.png"
                                        alt="Delete"
                                        className="bin-icon"
                                    />


                                    {/* {showDropdown && inputValue && currentInput == index && (
                                        <div style={{
                                            zIndex: 2000,
                                            background: 'pink',
                                            position: 'absolute',
                                            top: '0',
                                            left: '0',
                                            right: '0'
                                        }}>
                                            {filteredVariants.map((variant) => (
                                                <div
                                                    key={index}
                                                    onClick={() => handleItemClick(index, variant)}
                                                >
                                                    {variant}
                                                </div>
                                            ))}
                                        </div>
                                    )} */}
                                    {/* <button type="button" onClick={() => handleRemoveUser(index)} style={{ padding: '5px 10px', cursor: 'pointer' }}>Remove</button> */}
                                </div>
                            ))}
                        </div>
                        <img width="50" onClick={handleAddUser} style={{ background: '#bbbbbb', borderRadius: '5px', cursor: 'pointer' }} src={PLUS} />
                        {/* <button type="button" onClick={handleAddUser} style={{ padding: '10px', cursor: 'pointer' }}>Add User</button> */}

                    </div>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'right' }}>
                        <SubmitButtonStyle style={{ height: '30px', background: '#70e1c8' }} type="submit">Submit Team</SubmitButtonStyle>
                    </div>
                </form>
            </div >
        </div >
    );
};
