import React, { ChangeEvent, FormEvent, useRef, useState } from 'react';
import './NewDocumentModal.css'; // Assume basic styling here
import { SubmitButtonStyle } from '../../../utils/styles/index.styles';
import { Competence, DiscordUserType, Project, Team, User } from '../../../utils/types';
import PLUS from '../../../assets/plus.png';
import { useFetchUser } from '../../../utils/hooks/useFetchUser';
import doc_photo from '../../../assets/upload_doc.jpg'
import './Upload.css';

interface Props {
    project: Project
    isOpen: boolean,
    onClose: () => void,
    onFileUpload: (file: File, user: User, competences: Competence[], users?: DiscordUserType[]) => void;
}

interface Userr {
    name: string;
    email: string;
}

export const NewDocModal = ({ project, isOpen, onFileUpload, onClose }: Props) => {

    // const project: Project = JSON.parse(localStorage.getItem('project')!)
    const { user } = useFetchUser()

    const [name, setName] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [users, setUsers] = useState<Userr[]>([]);
    const [photoPreview, setPhotoPreview] = useState<string | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [photo, setPhoto] = useState<File | null>(null);
    const handleInputChange = (index: number, event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        const newUsers = [...users];
        newUsers[index] = { ...newUsers[index], [name]: value };
        setUsers(newUsers);
    };
    const [error, setError] = useState<string | undefined>(undefined)

    const handleAddUser = () => {
        setUsers([...users, { name: '', email: '' }]);
    };

    const handleRemoveUser = (index: number) => {
        const newUsers = users.filter((_, i) => i !== index);
        setUsers(newUsers);
    };


    const competencesList = [
        'Competence 1',
        'Competence 2',
        'Competence 3',
        'Competence 4',
        'Competence 1',
        'Competence 2',
        'Competence 3',
        'Competence 4',
        'Competence 1',
        'Competence 2',
        'Competence 3',
        'Competence 4',
        'Competence 1',
        'Competence 2',
        'Competence 3',
        'Competence 4'
    ];
    const [selectedCompetences, setSelectedCompetences] = useState<Competence[]>([]);

    const handleCompetenceChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value, checked, name } = event.target;
        const comp: Competence = { id: parseInt(value), name: name };
        setSelectedCompetences((prev) =>
            checked ? [...prev, comp] :
                prev.filter((competence) => competence.id !== parseInt(value))
        );
        // console.log(selectedCompetences)
    };
    const [selectedUsers, setSelectedUsers] = useState<DiscordUserType[]>([]);
    const handleUserChange = (user: DiscordUserType, event: ChangeEvent<HTMLInputElement>) => {
        const { checked, name } = event.target;

        setSelectedUsers((prev) =>
            checked ? [...prev, user] :
                prev.filter((competence) => competence.id !== (user.id))
        );
        // console.log(selectedUsers)
    };


    const handleImageClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const deleteImage = () => {
        setPhotoPreview(null);
        setPhoto(null)
    };
    const deleteFile = () => {
        setFile(null);
    };


    const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setName(value);

    }
    const hadnleDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = event.target;
        setDescription(value);
    }
    const handleSubmit = (event: FormEvent) => {


        const project = {
            name: name,
            creatorId: user?.discordId,
            description: description,
            sysRank: 0,
            competences: selectedCompetences,
            users: users,
            photo: photoPreview
        }
        // console.log(project)
        // handleNewProject(project, team.id)
        // console.log('Submitting form data:', finalFormData)

        // handleNewTeam(finalFormData);
        // Implement the upload functionality here
    };
    const [file, setFile] = useState<File | null>(null);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
    };


    const [tx, setTx] = useState('');
    const [deposited, setDeposited] = useState(false);
    const [deposit, setDeposit] = useState('');
    const [uploading, setUploading] = useState(false);
    const upload = async (file: File, user: User) => {
        if (!file) {
            setError('File cannot be empty!')
        } if (!(selectedCompetences.length > 0)) {
            setError('Competences cannot be empty!')
        } if (!(selectedUsers.length > 0)) {
            setError('Users cannot be empty!')
        }
        // console.log('category')
        // const users: User[] = await getParticipantsWallets(category);
        if (file && (selectedCompetences.length > 0) && (selectedUsers.length > 0)) {
            // console.log('huy')
            setUploading(true)
            onFileUpload(file, user, selectedCompetences, selectedUsers);
            setFile(null)
            setSelectedCompetences([])
            setSelectedUsers([])
            setError(undefined)
            onClose();
        }
        // setFile(null)
        // setUploading(false)

    }


    if (!isOpen) return null;

    return (
        <div className="modal-overlay" onClick={() => {
            onClose()
            setFile(null)
            setSelectedCompetences([])
            setSelectedUsers([])
            setError(undefined)
            onClose();
        }}>
            <div className="modal-content" style={{ height: 'max-content', width: '80%' }} onClick={e => e.stopPropagation()}>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <label style={{ fontSize: '25px' }}><b>NEW DOCUMENT</b></label>
                    {error && <label style={{ color: 'red' }}>{error}</label>}
                </div>
                <form onSubmit={(e) => (e.preventDefault(), file && user && upload(file, user))}>
                    <div style={{ height: '100%', width: '100%', display: 'grid', gridTemplateColumns: '5fr 5fr', columnGap: '10px' }}>
                        <div>
                            <>

                                {!file && (<><div style={{ display: "flex", height: '27vh', justifyContent: "center" }}>
                                    <input className="input" style={{ height: '27vh', width: '27vh' }} name="file" accept=".docx" type="file" onChange={handleFileChange} />
                                    <img src={doc_photo} style={{ height: '100%', borderRadius: '10px' }} />
                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" strokeLinejoin="round" strokeLinecap="round" viewBox="0 0 24 24" strokeWidth="2" fill="none" stroke="currentColor" className="icon">
                                            <polyline points="16 16 12 12 8 16"></polyline>
                                            <line y2="21" x2="12" y1="12" x1="12"></line>
                                            <path d="M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3"></path>
                                            <polyline points="16 16 12 12 8 16"></polyline>
                                        </svg> */}
                                </div><div style={{ display: 'flex', alignItems: "center", flexDirection: 'column' }}>
                                    </div></>)}

                                {file && (
                                    <div style={{ background: '#272727', height: '20vh', padding: '30px', borderRadius: '10px', boxShadow: '0 1px 6px rgba(0, 0, 0, 0.50)', margin: '20px', width: 'max-content' }}>
                                        <section style={{ width: 'max-content', }}>
                                            File details:
                                            <ul>
                                                <li>Name: {file.name}</li>
                                                <li>Size: {file.size} bytes</li>
                                            </ul>
                                        </section>
                                        {file && user &&
                                            (<SubmitButtonStyle style={{ margin: '0px', marginLeft: '40px', background: 'red' }} onClick={() => {
                                                deleteFile()
                                            }}>
                                                Delete document</SubmitButtonStyle>)}
                                    </div>
                                )}


                            </>

                        </div>

                        <div style={{ background: "#575757", borderRadius: '10px', padding: '10px' }}>
                            <div>
                                <div>
                                    <b><label htmlFor="competences">Competences:</label></b>
                                </div>
                                <div>
                                    <div>
                                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', maxHeight: '16vh', padding: '2px', rowGap: '3px', columnGap: '5px', overflowY: 'scroll' }}>
                                            {project.competences?.map((competence) => (
                                                <div style={{ background: "#bbb", alignItems: 'center', overflowY: 'hidden', overflowX: 'scroll', borderRadius: "5px" }}>
                                                    <label key={competence.id} style={{ height: '100%', margin: '0px', whiteSpace: "nowrap", display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                                        <input
                                                            type="checkbox"
                                                            value={competence.id}
                                                            name={competence.name}
                                                            onChange={handleCompetenceChange}
                                                        />
                                                        {competence.name}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div style={{ background: "#575757", borderRadius: '10px', padding: '10px', maxHeight: '40vh', marginTop: '20px' }}>
                        <label><b>Document Members:</b></label>
                        <div style={{ display: 'grid', overflowY: 'scroll', maxHeight: '30vh', gridTemplateColumns: '1fr 1fr 1fr', columnGap: '20px', width: '100%' }}>
                            {project.editors?.filter(editor => editor.username != user?.username).map((user, index) => (
                                <div key={index} className="user-field" style={{ background: '#bbb', borderRadius: '5px', display: 'flex', padding: '2px', width: '100%', marginTop: '2px', alignItems: 'center', marginBottom: '10px' }}>
                                    <input
                                        type="checkbox"
                                        name="selectedUsers"
                                        value={user.id} // Assuming user has an 'id' field
                                        onChange={(event) => handleUserChange(user, event)} // Handle checkbox change
                                        style={{ marginRight: '10px', width: '20px', height: '20px' }}
                                    />
                                    <span style={{ flexGrow: 1 }}>{user.username}</span> {/* Display user name */}

                                </div>
                            ))}
                        </div>
                        {/* <img width="50" onClick={handleAddUser} style={{ background: '#bbbbbb', borderRadius: '5px', cursor: 'pointer' }} src={PLUS} /> */}
                        {/* <button type="button" onClick={handleAddUser} style={{ padding: '10px', cursor: 'pointer' }}>Add User</button> */}

                    </div>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'right' }}>
                        {file && user &&
                            (<SubmitButtonStyle type="submit" style={{ height: '30px', background: '#70e1c8' }} onClick={() => {
                                // upload(file, user);

                            }}>
                                Upload a file</SubmitButtonStyle>)}                    </div>
                </form >
            </div >
        </div >
    );
};
