import React, { ChangeEvent, FormEvent, useRef, useState } from 'react';
import './NewDocumentModal.css'; // Assume basic styling here
import { Team, } from '../../utils/types';
import { SubmitButtonStyle } from '../../utils/styles/index.styles';

interface Props {
    team: Team
    isOpen: boolean,
    onClose: () => void,

}

interface User {
    name: string;
}

export const AboutTeamModal = ({ team, isOpen, onClose }: Props) => {

    const [name, setName] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [users, setUsers] = useState<User[]>([{ name: '' }]);
    const [photoPreview, setPhotoPreview] = useState<string | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [photo, setPhoto] = useState<File | null>(null);
    const handleInputChange = (index: number, event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        const newUsers = [...users];
        newUsers[index] = { ...newUsers[index], [name]: value };
        setUsers(newUsers);
    };
    const [error, setError] = useState('')

    const handleAddUser = () => {
        setUsers([...users, { name: '' }]);
    };

    const handleRemoveUser = (index: number) => {
        const newUsers = users.filter((_, i) => i !== index);
        setUsers(newUsers);
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            if (file) {
                setPhoto(file);
                const reader = new FileReader();
                reader.onloadend = () => {
                    setPhotoPreview(reader.result as string);
                };
                reader.readAsDataURL(file);
            } else {
                setPhotoPreview(null);
            }
        }
    };
    const competencesList = [
        'Competence 1',
        'Competence 2',
        'Competence 3',
        'Competence 4',
        'Competence 1',
        'Competence 2',
        'Competence 3',
        'Competence 4',
        'Competence 1',
        'Competence 2',
        'Competence 3',
        'Competence 4',
        'Competence 1',
        'Competence 2',
        'Competence 3',
        'Competence 4'
    ];
    const [selectedCompetences, setSelectedCompetences] = useState<string[]>([]);

    const handleCompetenceChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value, checked } = event.target;
        setSelectedCompetences((prev) =>
            checked ? [...prev, value] : prev.filter((competence) => competence !== value)
        );
    };

    const handleImageClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const deleteImage = () => {
        setPhotoPreview(null);
        setPhoto(null)
    };

    const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setName(value);

    }
    const hadnleDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = event.target;
        setDescription(value);
    }
    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        if (!name) {
            setError('Name cannot be empty!')
        } else if (!description) {
            setError('Description cannot be empty!')
        }

        const team = {
            name: name,
            creatorId: 'string',
            description: description,
            sysRank: 0,
            competences: selectedCompetences,
            users: users,
            photo: photoPreview
        }
        // console.log(team)



        // console.log('Submitting form data:', finalFormData);


        // Implement the upload functionality here
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" style={{ height: 'max-content', width: '80%' }} onClick={e => e.stopPropagation()}>
                <label style={{ fontSize: '25px' }}><b>TEAM INFO</b></label>
                {/* {error != '' && <label>{error}</label>} */}
                <div style={{ height: '100%', width: '100%', display: 'grid', gridTemplateColumns: '2fr 5fr 5fr', columnGap: '10px' }}>
                    <div>

                        <div style={{ position: "relative", width: "fit-content" }}>
                            <img
                                alt="Click to upload"
                                onClick={handleImageClick}
                                style={{
                                    cursor: 'pointer',
                                    background: '#BBBBBB',
                                    borderRadius: '10px',
                                    display: "block"
                                }}
                                src={team.photo?.url}
                                width="90%"
                            />
                        </div>
                    </div>
                    <div style={{ background: "#575757", borderRadius: '10px', padding: '10px' }}>
                        <div>
                            <div>
                                <b><label htmlFor="name">Team Name:</label></b>
                            </div>
                            <label>{team.name}</label>
                        </div>
                        <div style={{ marginTop: '10px', }}>
                            <div>
                                <b><label htmlFor="description">Description:</label></b>
                            </div>
                            <label>{team.description}</label>

                        </div>
                        {/* <div style={{ marginTop: '10px', }}>
                            <div>
                                <b><label htmlFor="description">System Rank:</label></b>
                            </div>
                            <label>{team.sysRank}</label>

                        </div> */}
                    </div>
                    <div style={{ background: "#575757", borderRadius: '10px', padding: '10px' }}>
                        <div>
                            <div>
                                <b><label htmlFor="competences">Competences:</label></b>
                            </div>
                            <div>
                                <div>
                                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', maxHeight: '16vh', padding: '2px', rowGap: '3px', columnGap: '5px', overflowY: 'scroll' }}>
                                        {/* {JSON.stringify(team.competences)} */}
                                        {team.competences?.map((competence) => {
                                            return (<div style={{ background: "#bbb", alignItems: 'center', overflowY: 'hidden', overflowX: 'scroll', borderRadius: "5px" }}>
                                                <label key={competence.id} style={{ height: '100%', color: 'black', margin: '0px', whiteSpace: "nowrap", display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                                    #{competence.name}
                                                </label>
                                            </div>)
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div style={{ background: "#575757", borderRadius: '10px', padding: '10px', maxHeight: '40vh', marginTop: '20px' }}>
                    <label><b>Team Members:</b></label>
                    <div style={{ display: 'grid', overflowY: 'scroll', maxHeight: '20vh', gridTemplateColumns: '1fr 1fr 1fr', columnGap: '20px', width: '100%' }}>
                        {team.editors?.map((editor) => {
                            return (<div style={{ background: "#bbb", alignItems: 'center', overflowY: 'hidden', overflowX: 'scroll', borderRadius: "5px" }}>
                                <label key={editor.id} style={{ height: '100%', color: 'black', margin: '0px', whiteSpace: "nowrap", display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                    #{editor.username}<b></b>
                                </label>
                            </div>)
                        })}
                    </div>
                    {/* <button type="button" onClick={handleAddUser} style={{ padding: '10px', cursor: 'pointer' }}>Add User</button> */}

                </div>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'right' }}>
                    {/* <SubmitButtonStyle style={{ height: '30px', background: 'red' }} >Close</SubmitButtonStyle> */}
                </div>
            </div >
        </div >
    );
};
