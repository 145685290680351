import { ChangeEvent, useMemo, useState, useContext } from 'react';
import { DocCanvasStyle, EditionPanelStyle, EditionPanelToggledStyle, InputField, NavBarStyle, ContainerStyle, SubmitButtonStyle, AddEntityButtonStyle } from '../../utils/styles/index.styles';
import { Doc, Paragraph, ParagraphEdition, PartialGuildChannel, User, RewardResults, Competence } from '../../utils/types';
import { InputFieldMD } from '../menuItems/InputFieldMD';
import { useFetchUser } from '../../utils/hooks/useFetchUser';

type Props = {
    paragraph: Paragraph;
    toggleHeight: string;
    handleFullToggle: (full: boolean, newPargraphBelow: string, entityCode: string) => void;
    fullToggle: boolean;
    setHtml: (html: string) => void;
    setActiveEditionProcess: (active: boolean) => void;
    handleFinalization: (documentId: number) => void;
    doc: Doc;
    entityCode: string,
    isgraded: boolean
    setIsgraded: (b: boolean) => void
    gradedComp: string[]
    setGradedComp: (s: string[]) => void
    handleGrade: (edition: ParagraphEdition, user: User, grade: number, competence: Competence) => void;
    // getParticipantsWallets: (category: PartialGuildChannel) => Promise<User[]>;
    update: (doc: Doc) => void
    setIsEditionGraded: (bb: boolean) => void
    setIsParagraphUpdated: (p: Paragraph | undefined) => void
    // setEditions: (editions: ParagraphEdition[]) => void;
};


export const EditionPanel = ({ paragraph, setIsParagraphUpdated, doc, entityCode, setIsEditionGraded, isgraded, setIsgraded, update, gradedComp, setGradedComp, toggleHeight, handleGrade, handleFinalization, handleFullToggle, fullToggle, setHtml, setActiveEditionProcess }: Props) => {

    const { user } = useFetchUser();
    const maxEd = paragraph.editions?.sort((a: ParagraphEdition, b: ParagraphEdition) => {
        if (a.sysRank > b.sysRank) return -1;
        if (a.sysRank < b.sysRank) return 1;
        if (a.id > b.id) return -1;
        if (a.id < b.id) return 1;
        return 0;
    })[0];
    const grades = Array.from({ length: 10 }, (_, i) => i + 1);
    const edition: ParagraphEdition = JSON.parse(localStorage.getItem('edition')!);
    const graded = edition != null ? edition.grades : maxEd.grades;

    const setGrade = (grade: number, competence: Competence) => {
        isUserGraded(competence, true);

        if (edition == null) {
            isUserGraded(competence, true);
            setGradedComp([...gradedComp, competence.id.toString()])
            handleGrade(maxEd, user!, grade, competence)
            setIsParagraphUpdated(paragraph)
            update(doc)
        } else {
            isUserGraded(competence, true);
            setGradedComp([...gradedComp, competence.id.toString()])
            handleGrade(edition, user!, grade, competence)
            setIsEditionGraded(true)
            // setIsParagraphUpdated(edition)
        }

    }
    const isUserGraded = (competence: Competence, bool: boolean) => {
        setIsgraded(bool)
        graded?.map((grade) => (
            (grade.discordId === user!.discordId && grade.competenceId == competence.id) ? (setIsgraded(true)) : (setIsgraded(false))));
        return isgraded;
    }

    return (
        <EditionPanelStyle style={{ height: toggleHeight }}>
            {entityCode != 'grade' ? (fullToggle ? (
                <EditionPanelToggledStyle onClick={() => { setActiveEditionProcess(true) }}>

                    <ContainerStyle style={{ padding: '0px' }} >
                        <InputFieldMD setActiveEditionProcess={setActiveEditionProcess} onHTMLChange={(e) => { setHtml(e) }} paragraph={paragraph} />
                    </ContainerStyle>

                    <ContainerStyle style={{ background: '#ffffff' }}>
                        <div style={{ height: '100%', width: '100%', justifyContent: 'center' }}>
                            <ContainerStyle style={{ height: 'auto', background: '#70e1c8' }}>
                                <h5 style={{ margin: '2px' }}>DOCUMENT STATISTICS</h5>
                            </ContainerStyle>
                            <ContainerStyle style={{ display: 'flex', boxShadow: 'none', flexDirection: 'column', marginTop: '2%', justifyContent: 'center', height: 'auto' }}>
                                {/* <span style={{ marginTop: '4px' }}>
                                    Rating: <b>0</b>
                                </span>
                                <span style={{ marginTop: '4px' }}>
                                    Approved paragraphs: <b>0</b>
                                </span> */}
                                <label>soon...</label>

                            </ContainerStyle>
                        </div>
                        {/* <button onClick={() => { }}> submit </button> */}
                    </ContainerStyle>
                </EditionPanelToggledStyle>
            ) : (

                <div style={{ height: '100%', marginLeft: '25px', marginRight: '25px', display: 'grid', gridTemplateColumns: '2fr 2fr 1fr', columnGap: '20px' }}>

                    <AddEntityButtonStyle onClick={() => { handleFullToggle(true, (paragraph!.id).toString(), 'paragraph') }}> ADD PARAGRAPH BELOW</AddEntityButtonStyle>
                    <AddEntityButtonStyle onClick={() => { handleFullToggle(true, '', 'edition') }}> ADD EDITION TO CURRENT PARAGRAPH</AddEntityButtonStyle>
                    <AddEntityButtonStyle style={{ background: "#f2ef42" }} onClick={() => { handleFullToggle(true, '', 'grade') }}> SET GRADES</AddEntityButtonStyle>

                    {/* {paragraph.document.creatorId === user?.discordId && (publicKey ?
                        (<AddEntityButtonStyle style={{ background: '#512da8', color: '#fff' }} onClick={async () => { await finalise(paragraph.document.id) }}><b>FINALISE</b></AddEntityButtonStyle>
                        ) : (
                            // <AddEntityButtonStyle style={{ background: '#512da8', color: '#fff' }} onClick={async () => { }}><b>CONNECT WALLET TO FINALISE</b></AddEntityButtonStyle>
                            <WalletModalProvider >

                                <BaseWalletMultiButton style={{
                                    fontSize: '13px', lineHeight: '13px', margin: '0px'
                                }} labels={LABELS} />
                            </WalletModalProvider>
                        ))
                    } */}
                </div>
            )
            ) : (
                fullToggle ? (<EditionPanelToggledStyle style={{ gridTemplateColumns: '3.5fr 1fr' }}>
                    <ContainerStyle style={{ padding: '0px', maxHeight: '28vh', width: '100%', gridTemplateRows: '1fr 1fr 1fr 1fr', background: '#bbb', gridTemplateColumns: '1fr 1fr', display: 'grid', overflowY: 'scroll' }}>
                        {doc.competences?.map((competence) => {
                            // isUserGraded(competence)
                            return (
                                <> {!graded?.some(grade =>
                                    grade.discordId === user!.discordId && grade.competenceId === competence.id
                                ) ?
                                    (<ContainerStyle style={{ margin: '5px', height: 'auto', display: 'grid', gridTemplateColumns: '9fr 1fr' }} onClick={(e) => (e.stopPropagation())}>
                                        {!(isgraded && gradedComp.includes(competence.id.toString())) ? <div style={{ display: 'grid', gridTemplateColumns: 'repeat(10, 1fr)' }}>{
                                            (grades.map((grade) => (
                                                <><SubmitButtonStyle onClick={() => setGrade(grade, competence)} style={{ padding: '10px', marginLeft: '5px' }}
                                                >{grade}</SubmitButtonStyle></>
                                            )))
                                        }</div> : <div style={{ display: 'flex', alignItems: 'center' }}>{
                                            <label >You have already graded this competence in this paragraph.</label>
                                        }</div>}
                                        {!paragraph.isApproved &&
                                            <div style={{ display: 'flex', margin: 0, alignItems: 'center', justifyContent: 'center', background: '#fff' }}
                                            >
                                                <label style={{ marginLeft: '10px', marginRight: '10px' }}>#{competence.name}</label>
                                            </div>
                                        }
                                    </ContainerStyle>) :
                                    (<ContainerStyle style={{ margin: '5px', height: 'auto', display: 'grid', gridTemplateColumns: '9fr 1fr' }} onClick={(e) => (e.stopPropagation())}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>{
                                            <label>You have already graded this competence in this paragraph.</label>
                                        }</div>
                                        {!paragraph.isApproved &&
                                            <div style={{ display: 'flex', margin: 0, alignItems: 'center', justifyContent: 'center', background: '#fff' }}
                                            >
                                                <label style={{ marginLeft: '10px', marginRight: '10px' }}>#{competence.name}</label>
                                            </div>
                                        }
                                    </ContainerStyle>

                                    )}
                                </>
                            )
                        })}

                    </ContainerStyle>

                    <ContainerStyle style={{ background: '#ffffff', }}>
                        <div style={{ justifyContent: 'center' }}>
                            <ContainerStyle style={{ height: 'auto', background: '#70e1c8' }}>
                                <h5 style={{ margin: '2px' }}>GRADING STATISTICS</h5>
                            </ContainerStyle>
                            <ContainerStyle style={{ display: 'flex', boxShadow: 'none', flexDirection: 'column', marginTop: '2%', justifyContent: 'center', height: 'auto' }}>
                                {/* <span style={{ marginTop: '4px' }}>
                                    Rating:{edition!=null .sysRank} <b>RIT</b>
                                </span> */}
                                <label>soon</label>
                                {/* <span style={{ marginTop: '4px' }}>
                                    Approved paragraphs: <b>0</b>
                                </span> */}
                                {/* <SubmitButtonStyle style={{ color: 'black', background: '#f2ef42' }} >

                                    SUBMIT GRADES
                                </SubmitButtonStyle> */}
                            </ContainerStyle>
                        </div>

                    </ContainerStyle>

                </EditionPanelToggledStyle>) : (
                    <div style={{ height: '100%', marginLeft: '25px', marginRight: '25px', display: 'grid', gridTemplateColumns: '2fr 2fr 1fr', columnGap: '20px' }}>

                        <AddEntityButtonStyle onClick={() => { handleFullToggle(true, (paragraph!.id).toString(), 'paragraph') }}> ADD PARAGRAPH BELOW</AddEntityButtonStyle>
                        <AddEntityButtonStyle onClick={() => { handleFullToggle(true, '', 'edition') }}> ADD EDITION TO CURRENT PARAGRAPH</AddEntityButtonStyle>
                        <AddEntityButtonStyle style={{ background: "#f2ef42" }} onClick={() => { handleFullToggle(true, '', 'grade') }}> SET GRADES</AddEntityButtonStyle>

                        {/* {paragraph.document.creatorId === user?.discordId && (publicKey ?
                          (<AddEntityButtonStyle style={{ background: '#512da8', color: '#fff' }} onClick={async () => { await finalise(paragraph.document.id) }}><b>FINALISE</b></AddEntityButtonStyle>
                          ) : (
                              // <AddEntityButtonStyle style={{ background: '#512da8', color: '#fff' }} onClick={async () => { }}><b>CONNECT WALLET TO FINALISE</b></AddEntityButtonStyle>
                              <WalletModalProvider >
  
                                  <BaseWalletMultiButton style={{
                                      fontSize: '13px', lineHeight: '13px', margin: '0px'
                                  }} labels={LABELS} />
                              </WalletModalProvider>
                          ))
                      } */}
                    </div>
                )
            )}

        </EditionPanelStyle >
    )
};
