import { useEffect, useState } from 'react';
import { getCompetences, getMutualGuilds, getTeams, postNewProject, postNewTeam } from '../api';
import { Competence, PartialGuild, ProjectDetails, Team, TeamDetails } from '../types';

export function useFetchCompetnces() {
    const [competences, setTeam] = useState<Competence[]>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {
        setLoading(true);
        getCompetences()
            .then(({ data }) => {
                setTeam(data);
            })
            .catch((err) => {
                setError(err);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);



    return { competences, loading, error };
}
