import { createContext } from 'react';
import { PartialGuildChannel } from '../types';

type CategoryContextType = {
  category?: PartialGuildChannel;
  updateCategory: (category: PartialGuildChannel) => void;
};

export const CategoryContext = createContext<CategoryContextType>({
  updateCategory: (category: PartialGuildChannel) => { localStorage.setItem('category', JSON.stringify(category)) },
});
