import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { MoonLoader } from 'react-spinners';
import { TeamMenuItem } from '../components/team/Team';
import { CategoryContext } from '../utils/contexts/CategoryContext';
import { useFetchGuilds } from '../utils/hooks/useFetchGuilds';
import { Container, Flex, Page } from '../utils/styles/index.styles';
import { PartialGuild } from '../utils/types';


export const AccountPage = () => {
    const navigate = useNavigate();
    // const { updateGuild } = useContext(GuildContext);
    // const { guilds, loading } = useFetchGuilds();

    const handleClick = (guild: PartialGuild) => {
        // updateGuild(guild);
        navigate('/documents');
    };

    return (
        <Page>
            <Container style={{ height: '100%', width: '100%', }}>
            </Container>
        </Page>
    );
};

