import { AppBarStyle, Bars, Container, Nav, NavBarStyle, NavBtn, NavBtnLink, NavMenu } from '../../utils/styles/index.styles';
import NestJSIcon from '../assets/nestjs.png';
import { useContext } from 'react';
import { NavLink, Navigate } from 'react-router-dom';
import { getIconURL } from '../../utils/helpers';
import Navbar from 'react-bootstrap/Navbar';

import { useFetchUser } from '../../utils/hooks/useFetchUser';
import { ReactComponent as YourSvg } from '../../assets/RATEIT.svg';
import DISCOR_AV from '../../assets/discord_avatar.png'
export const NavBar = () => {
    const { user } = useFetchUser();
    return (
        <Nav>
            <Bars />
            <NavMenu >
                <YourSvg height={50} width={'100px'} style={{ marginRight: '5px' }} />
                <NavLink style={{ textDecoration: 'none', color: '#70e1c8' }} to="/menu" >
                    <h1>RateIT</h1>
                </NavLink>

            </NavMenu>
            {/* <NavMenu style={{ marginRight: '0px' }}> */}
            {/* <h4 style={{ color: 'white', marginBottom: '10px' }}>{user?.username}</h4> */}

            {user?.avatar
                ? (

                    <img
                        src={`https://cdn.discordapp.com/avatars/${user?.discordId}/${user?.avatar}.png`}
                        style={{ margin: '10px', borderRadius: '5px', height: '75%', border: '2px solid white' }}
                    />) :
                (
                    <img
                        src={DISCOR_AV}

                        style={{ borderRadius: '5px', margin: '10px', height: '75%', border: '2px solid white' }}
                    />
                )}
            {/* </NavMenu> */}
        </Nav >
        // </NavBarStyle>
    )
};
