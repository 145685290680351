import { useEffect, useState } from 'react';
import { getCategoryDocuments, postDocument } from '../api';
import { Competence, DiscordUserType, Doc, DocDetails, PartialGuildChannel, User } from '../types';

// import { AES, } from 'crypto-es/lib/aes';

// import crypto from 'crypto';
// import * as dotenv from 'dotenv';
// var randomBytes = require('random-bytes')


export function useFetchDocuments() {
  const [documents, setDocuments] = useState<Doc[]>();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<User>();
  const project = JSON.parse(localStorage.getItem('project')!);
  const category = JSON.parse(localStorage.getItem('category')!);



  const handleUpload = (file: File, user: User, competences: Competence[], users?: DiscordUserType[]) => {
    try {
      const docDetails: DocDetails = {
        categoryId: project.id,
        deposit: 0,
        name: file.name,
        cipher: 'encoded',
        isFinalised: false,
        accountHash: 'hash',
        walletId: 'hash',
        creatorId: user.discordId,
        description: "file-hehe",
        competences: competences,
        editors: users,
        sysRank: 0
      };
      setLoading(true);
      postDocument(file!, docDetails)
        .then((data) => {
          setDocuments(data);
        })
        .catch((err) => {

          setError(err);
        })
        .finally(() => setLoading(false));

    } catch (e) {
      console.error(e)
    }
  };

  useEffect(() => {
    setLoading(true);
    getCategoryDocuments(project.id)
      .then(({ data }) => {
        setDocuments(data);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => setLoading(false));
  }, []);

  return { documents, setLoading, error, loading, handleUpload };
}
