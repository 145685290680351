import { createContext } from 'react';
import { Doc } from '../types';

type DocContextType = {
    doc?: Doc;
    updateDoc: (doc: Doc) => void;
};

export const DocContext = createContext<DocContextType>({
    updateDoc: (doc: Doc) => { localStorage.setItem('doc', JSON.stringify(doc)) }
});
