import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import {
  GuildModLogType,
  GuildConfigType,
  PartialGuild,
  User,
  GuildBanType,
  DocDetails,
  Doc,
  Paragraph,
  ParagraphEdition,
  ParagraphEditionDetails,
  Category,
  PartialGuildChannel,
  ParagraphDetails,
  CommentDetails,
  Grade,
  DiscordUserType,
  ProjectDetails,
  Team,
  TeamDetails,
  Competence,
  Project,
} from './types';

import { useFetchUser } from './hooks/useFetchUser';


const CONFIG: AxiosRequestConfig = { withCredentials: true };

const API_URL = 'https://rateit.expert/api/v1';
// const API_URL = 'http://localhost:3001/v1';

export const getAuthStatus = () =>
  axios.get<User>(`${API_URL}/auth/status`, CONFIG);

export const getUser = async (id: string) => {
  const { data: user } = await axios.get<DiscordUserType>(`${API_URL}/users/${id}`, CONFIG);
  return user;
}

export const getProject = async (id: number) => {
  return await axios.get<Project>(`${API_URL}/projects/${id}`, CONFIG);
}

export const getCategoryDocuments = (id: number) =>
  axios.get<Doc[]>(`${API_URL}/documents/${id}`, CONFIG);

export const getMutualGuilds = () =>
  axios.get<PartialGuild[]>(`${API_URL}/discord/guilds`, CONFIG);

export const getTeams = () =>
  axios.get<Team[]>(`${API_URL}/teams`, CONFIG);

export const getCompetences = () =>
  axios.get<Competence[]>(`${API_URL}/competences`, CONFIG);

export const getParagraphEditions = (docId: number, parId: number) =>
  axios.get<ParagraphEdition[]>(
    `${API_URL}/documents/${docId}/paragraphs/${parId}`, CONFIG
  );

export const getDocumentParagraphs = (docId: number, page: number) =>
  axios.get(
    `${API_URL}/documents/${docId}/paragraphs?page=${page}`, CONFIG
  );

export const postDocument = async (file: File, details: DocDetails): Promise<Doc[]> => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append('details', JSON.stringify(details))
  const { data: documents } = await axios.post(`${API_URL}/documents/upload`,
    formData, {
    withCredentials: true,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return documents;
}

export const postNewParagraphEdition = async (paragraphEditionDetails: ParagraphEditionDetails, id: number) => {
  // formData.append("paragraph", JSON.stringify(paragraphEditionDetails.paragraph));
  const { data: edition } = await axios.post<ParagraphEdition[]>(
    `${API_URL}/documents/${id}/editions`,
    JSON.stringify(paragraphEditionDetails)
    , {
      headers: {
        'Content-Type': 'application/json',
      }
    },
    // CONFIG
  );
  return edition;

}
export const getUsers = () =>

  axios.get(
    `${API_URL}/users`, CONFIG
  );


export const postFinalisation = async (documentId: number) => {
  const { data: doc } = await axios.post<Doc>(
    `${API_URL}/documents/${documentId}/finalisation`,
    { isFinaluzed: true, documentId: documentId }
    , {
      headers: {
        'Content-Type': 'application/json',
      }
    },
  );
  return doc;
}

export const postNewProject = async (projectDetails: ProjectDetails, id: number) => {
  const formData = new FormData();
  if (projectDetails.photo) {
    formData.append('photo', projectDetails.photo);
  } else {
    formData.append('photo', 'undefined');

  }
  projectDetails.photo = undefined;

  formData.append('project', JSON.stringify(projectDetails));
  // console.log(formData)
  const { data: project } = await axios.post<Project>(
    `${API_URL}/projects/${id}`,
    formData
    , {
      withCredentials: true,
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    }
  );
  // console.log('proj' + project.id)
  return project;
}

export const postNewTeam = async (teamDetails: TeamDetails) => {
  const formData = new FormData();
  if (teamDetails.photo) {
    formData.append('photo', teamDetails.photo);
  } else {
    formData.append('photo', 'undefined');
  }
  teamDetails.photo = undefined;
  formData.append('team', JSON.stringify(teamDetails));
  const { data: team } = await axios.post<Team>(
    `${API_URL}/teams`,
    formData
    , {
      withCredentials: true,
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    }
  );
  return team;
}


export const postNewParagraph = async (paragraphDetails: ParagraphDetails) => {
  const { data: paragraph } = await axios.post<Paragraph[]>(
    `${API_URL}/documents/${paragraphDetails.document.id}/paragraph`,
    JSON.stringify(paragraphDetails)
    , {
      headers: {
        'Content-Type': 'application/json',
      }
    },
  );
  return paragraph;
}

export const postEditionGrade = async (grade: number, user: User, edition: ParagraphEdition, competence: Competence) => {
  const userGrade: Grade = {
    discordId: user.discordId,
    grade: grade,
    username: user.username,
    competenceId: competence.id,
    competenceName: competence.name
  }
  const { data: paragraph } = await axios.post<Paragraph>(
    `${API_URL}/documents/${edition!.id}/edition/grade`,
    JSON.stringify(userGrade)
    , {
      headers: {
        'Content-Type': 'application/json',
      }
    },
  );
  return paragraph;
}

export const postParagraphGrade = async (grade: number, user: User, paragraph: Paragraph, competence: Competence) => {
  const userGrade: Grade = {
    discordId: user.discordId,
    grade: grade,
    username: user.username,
    competenceId: competence.id,
    competenceName: competence.name
  }
  axios.put(
    `${API_URL}/documents/${paragraph!.id}/paragraph/grade`,
    JSON.stringify(userGrade)
    , {
      headers: {
        'Content-Type': 'application/json',
      }
    },
  );
}

export const postNewParagraphComment = async (commentDetails: CommentDetails) => {
  const { data: paragraph } = await axios.post(
    `${API_URL}/documents/${commentDetails.paragraph!.id}/paragraph/comment`,
    JSON.stringify(commentDetails)
    , {
      headers: {
        'Content-Type': 'application/json',
      }
    },
  );
  return paragraph;
}

export const getParagraphComments = (paragraphId: string) =>
  axios.get(
    `${API_URL}/documents/${paragraphId}/paragraph/comment`, CONFIG
  );


export const getEditionComments = (editionId: string) =>
  axios.get(
    `${API_URL}/documents/${editionId}/edition/comment`, CONFIG
  );

export const postNewEditionComment = async (commentDetails: CommentDetails) => {
  const { data: edition } = await axios.post(
    `${API_URL}/documents/${commentDetails.edition!.id}/edition/comment`,
    JSON.stringify(commentDetails)
    , {
      headers: {
        'Content-Type': 'application/json',
      }
    },
  );
  return edition;
}

export const updateUserWallet = (publicKey: string, discordId: string) => {
  const details = {
    discordId: discordId,
    publicKey: publicKey
  }
  axios.put(`${API_URL}/solana/update`,
    { data: details }, CONFIG
  );
}

export const getGuildCategories = async (guildId: string, userId: string) => {
  const { data: categories } = await axios.get(`${API_URL}/discord/guilds/${guildId}/categories/${userId}`,
    CONFIG
  );
  return categories;
}

