import { useEffect, useState } from 'react';
import { getDocumentParagraphs, getParagraphEditions, postEditionGrade, postNewParagraphEdition } from '../api';
import { Competence, Doc, GuildConfigType, Paragraph, ParagraphEdition, ParagraphEditionDetails, User } from '../types';

export function useFetchParagraphEditions(document: Doc, paragraph: Paragraph) {

    const [editions, setEditions] = useState<ParagraphEdition[]>();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();

    const handleGrade = (edition: ParagraphEdition, user: User, grade: number, competence: Competence) => {
    }

    const updatePars = (document: Doc) => {
        getParagraphEditions(document.id, paragraph.id)
            .then(response => {

                setEditions(response.data.sort((a: ParagraphEdition, b: ParagraphEdition) => {
                    if (a.sysRank > b.sysRank) return -1;
                    if (a.sysRank < b.sysRank) return 1;
                    if (a.id > b.id) return -1;
                    if (a.id < b.id) return 1;
                    return 0;
                }).slice(1));
                // console.log('huy')
            })
    }

    const handleNewParagraphEdition = (content: string, paragraph: Paragraph, user: User) => {
        try {
            const paragraphEditionDetails: ParagraphEditionDetails = {
                creatorId: user.discordId,
                content: content,
                sysRank: 0.00001,
                paragraph: paragraph
            }
            postNewParagraphEdition(paragraphEditionDetails, document.id).then(editions => {
                setEditions(editions?.sort((a: ParagraphEdition, b: ParagraphEdition) => {
                    if (a.sysRank > b.sysRank) return -1;
                    if (a.sysRank < b.sysRank) return 1;
                    if (a.id > b.id) return -1;
                    if (a.id < b.id) return 1;
                    return 0;
                }).slice(1))
            });
        } catch (e) {

        }
    };

    useEffect(() => {
        setLoading(true);
        getParagraphEditions(document.id, paragraph.id)
            .then(({ data }) => {
                setEditions(data.sort((a: ParagraphEdition, b: ParagraphEdition) => {
                    if (a.sysRank > b.sysRank) return -1;
                    if (a.sysRank < b.sysRank) return 1;
                    if (a.id > b.id) return -1;
                    if (a.id < b.id) return 1;
                    return 0;
                }).slice(1));;
            })
            .catch((err) => {
                setError(err);
            })
            .finally(() => setTimeout(() => setLoading(false)));
    }, [document.id, paragraph.id]);


    return { editions, loading, error, handleNewParagraphEdition, handleGrade, updatePars };
}
