import { useContext, useEffect, useRef, useState } from "react"
import { Button, ContainerStyle, DocCanvasStyle, SubmitButtonStyle } from "../../utils/styles/index.styles"
import { Competence, Doc, Paragraph, ParagraphEdition, User } from "../../utils/types"
import { Markdown } from "../document/Markdown"
import { useFetchParagraphEditions } from "../../utils/hooks/useFetchParagraphEditions"
import { useFetchUser } from "../../utils/hooks/useFetchUser"
import { postEditionGrade } from "../../utils/api"
import React from "react"
import { ParagraphContext } from "../../utils/contexts/ParagraphContext"

type Props = {
    html: string;
    paragraph: Paragraph;
    activeEditionProcess: boolean;
    toggleEditionCommentPanel: (edition: ParagraphEdition) => void;
    setActiveEditionProcess: (submitted: boolean) => void;
    entityCode: string;
    setEdition: Function;
    setCommentCode: Function;
    setActiveCommentingProcess: Function;
    setFullToggle: (full: boolean) => void;
    paragraphs: Paragraph[];
    handleNewParagraph: (content: string, paragraph: Paragraph, user: User) => void;
    update: (doc: Doc) => void;
    handleGradeEd: (edition: ParagraphEdition, user: User, grade: number, competence: Competence) => void;
    doc: Doc;
    isEditionGraded: boolean
    setIsEditionGraded: (bb: boolean) => void
}

export const EditionsContainer = ({ html, setIsEditionGraded, doc, isEditionGraded, handleGradeEd, handleNewParagraph, update, paragraph, setFullToggle, setActiveCommentingProcess, setCommentCode, activeEditionProcess, setEdition, toggleEditionCommentPanel, setActiveEditionProcess, entityCode }: Props) => {
    const { user } = useFetchUser();
    const [hovered, setHovered] = useState(false);
    const [activeEdition, setActiveEdition] = useState<ParagraphEdition | undefined>(undefined);
    const [hoveredEdition, setHoveredEdition] = useState<ParagraphEdition | undefined>(undefined);
    const [editionGraded, setEditionGraded] = useState<boolean>(false);
    const [userGrade, setUserGrade] = useState<number | undefined>(undefined);
    const [showGrades, setShowGrades] = useState(false);

    const checkIfUserGraded = (edition: ParagraphEdition) => {
        setUserGrade(undefined);
        setEditionGraded(false)
        edition.grades!.map((grade) => (
            (grade.discordId === user!.discordId) ? (setUserGrade(grade.grade!), setEditionGraded(true)) : (setUserGrade(undefined), setEditionGraded(false))));
    }

    const { editions, handleNewParagraphEdition, handleGrade, updatePars } = useFetchParagraphEditions(doc, paragraph);
    // console.log('dddd' + isEditionGraded)
    if (isEditionGraded) {
        setTimeout(() => (updatePars(doc), 3500))
        setIsEditionGraded(false)
        // setIsEditionUpdated(activeEdition)
    }

    const handleGradeClick = () => {
        // console.log('huy')
        showGrades ? setShowGrades(false) : setShowGrades(true)
    }

    const handleEditionClick = (edition: ParagraphEdition) => {
        if (activeEdition === edition) {
            setActiveEdition(undefined);
            setActiveCommentingProcess(false)
            setFullToggle(false)
            checkIfUserGraded(edition);
            localStorage.removeItem('edition');
            setShowGrades(false)
        } else {
            setShowGrades(false)
            setEdition(edition);
            setActiveEdition(edition);
            checkIfUserGraded(edition);
            setFullToggle(false)
            setActiveCommentingProcess(false);
            localStorage.setItem('edition', JSON.stringify(edition))
        }
    }

    const handleHovered = (hovered: boolean, paragraph: ParagraphEdition | undefined) => {
        setHovered(hovered);
        setHoveredEdition(paragraph)
    }

    const myRef = useRef<null | HTMLDivElement>(null);

    const executeScroll = () => myRef.current?.scrollIntoView()

    useEffect(() => {
        executeScroll();
    }, [html]);

    const grades = Array.from({ length: 10 }, (_, i) => i + 1);
    return (
        <>
            {!(editions && editions!.length > 0) ? <p style={{ color: '#fff' }}>NO EDITIONS....</p> : <DocCanvasStyle style={{ overflowY: 'scroll', color: 'none' }}>
                {
                    editions?.map((edition) => (
                        <ContainerStyle style={{
                            scale: (hoveredEdition == edition) ? ('101%') : ('100%'), cursor: 'pointer', background: (activeEdition == edition) ? ('#e5decf') : ('#fff')
                        }}
                            onClick={() => {

                                handleEditionClick(edition);
                            }}
                            key={"editions-" + edition.id}
                            onMouseEnter={() => handleHovered(true, edition)} onMouseLeave={() => handleHovered(false, undefined)}>
                            <Markdown content={edition.content} />

                            {((activeEdition == edition)) && (
                                <ContainerStyle style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ width: 'max-content' }}>
                                        {/* ( */}
                                        {/* <><b>Your grade: </b><i>{userGrade ? userGrade : '-'}</i> || <b>Rating: </b><i>{getParagraphCurrentEdition(paragraph).sysRank}</i></>) : ( */}
                                        <><b>Current status:</b> {paragraph.isApproved ? <>APPROVED✅</> : <>NOT APPROVED ❌</>} <b> Current rating: </b><i>{Number(edition.sysRank).toFixed(3)}/10</i>  {edition.grades && edition.grades!.length > 0 && <SubmitButtonStyle style={{ cursor: 'pointer', color: 'black', background: '#bbbbbb', borderRadius: '5x' }} onClick={(e) => (e.stopPropagation(), handleGradeClick())}>check grades</SubmitButtonStyle>}</>
                                        {showGrades && <ContainerStyle style={{ display: 'grid' }}>
                                            {edition.grades?.map((grade) => {
                                                return (
                                                    <label> User {grade.username} set {grade.grade} RIT to #{grade.competenceName}</label>
                                                )
                                            })}
                                        </ContainerStyle>}
                                        {/* ) */}
                                    </div>
                                    {/* <div>{JSON.stringify(getParagraphCurrentEdition(paragraph).grades)}</div> */}
                                    {!paragraph.isApproved &&
                                        <div>
                                            <SubmitButtonStyle style={{ margin: 0, marginTop: '10px', height: 'auto', background: '#70e1c8' }}
                                                onClick={(e) =>
                                                (e.stopPropagation(),
                                                    setCommentCode('edition'),
                                                    setActiveCommentingProcess(true),
                                                    toggleEditionCommentPanel(edition)
                                                )
                                                }>
                                                COMMENTS
                                            </SubmitButtonStyle>
                                        </div>
                                    }
                                </ContainerStyle>
                            )}
                        </ContainerStyle>
                    )
                    )
                }
            </DocCanvasStyle >}
            {
                (activeEditionProcess && entityCode == 'edition' && html != '') && (
                    <ContainerStyle ref={myRef} style={{ marginTop: '10px', background: '#fff', }}>
                        <ContainerStyle style={{ background: '#70e1c8' }}><span>Suggested edition: </span>
                        </ContainerStyle>
                        <Markdown content={html} />
                        <div style={{ display: 'flex', justifyContent: 'right', marginRight: '20px' }}>
                            <SubmitButtonStyle onClick={() =>
                            (handleNewParagraphEdition(html, paragraph, user!),
                                setActiveEditionProcess(false),
                                setTimeout(() => updatePars(doc), 3000))
                            }>

                                SUBMIT TO PARAGRAPH
                            </SubmitButtonStyle>
                        </div>
                    </ContainerStyle>)
            }
        </>);
}