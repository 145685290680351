import { createContext } from 'react';
import { PartialGuildChannel, Project } from '../types';

type ProjectContextType = {
    project?: Project;
    updateProject: (project: Project) => void;
};


export const ProjectContext = createContext<ProjectContextType>({
    updateProject: (project: Project) => { localStorage.setItem('project', JSON.stringify(project)) },
});
