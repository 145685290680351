import { AppBarStyle } from '../utils/styles/index.styles';
import NestJSIcon from '../assets/nestjs.png';
import { useContext } from 'react';
import { CategoryContext } from '../utils/contexts/CategoryContext';
import { Navigate } from 'react-router-dom';
import { getIconURL } from '../utils/helpers';

export const AppBar = () => {
  const { category } = useContext(CategoryContext);


  return category ? (
    <AppBarStyle>
      <h1 style={{ fontWeight: 'normal', fontSize: '20px' }}>
        Configuring {category.name}
      </h1>
      <img
        height={55}
        width={55}
        style={{
          borderRadius: '50%',
        }}
        alt="logo"
      />
    </AppBarStyle>
  ) : (
    <Navigate replace to="/menu" />
  );
};
