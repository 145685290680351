import { useEffect, useRef, useState } from "react";
import { useFetchDocumentParagraphs } from "../../utils/hooks/useFetchDocumentParagraphs";
import { ContainerStyle, SubmitButtonStyle } from "../../utils/styles/index.styles";
import { Competence, Doc, Paragraph, ParagraphEdition, User } from "../../utils/types";
import { Markdown } from "../document/Markdown";
import { useFetchUser } from "../../utils/hooks/useFetchUser";
import { postParagraphGrade } from "../../utils/api";

type Props = {
    doc: Doc
    toggleCommentPanel: (paragraph: Paragraph) => void;
    toggleEditionPanel: (paragraph: Paragraph) => void;
    toggleEditionCommentPanel: (edition: ParagraphEdition) => void;
    newParagraph: string;
    entityCode: string;
    activeEditionProcess: boolean;
    setActiveEditionProcess: (submitted: boolean) => void;
    html: string;
    setCommentCode: Function;
    setActiveCommentingProcess: Function;
    paragraphs: Paragraph[];
    handleNewParagraph: (content: string, paragraph: Paragraph, user: User) => void;
    handleGrade: (edition: ParagraphEdition, user: User, grade: number, competence: Competence) => void;
    update: (doc: Doc) => void;
    setIsParagraphUpdated: (pp: Paragraph | undefined) => void
    isParagraphUpdated: Paragraph | undefined
}

export const ParagraphContainer = ({ doc, paragraphs, isParagraphUpdated, setIsParagraphUpdated, handleNewParagraph, toggleEditionCommentPanel, handleGrade, update, setActiveCommentingProcess, setCommentCode, toggleEditionPanel, toggleCommentPanel, newParagraph, html, activeEditionProcess, setActiveEditionProcess, entityCode }: Props) => {
    const { user } = useFetchUser();
    const [activeParagraph, setActiveParagraph] = useState<Paragraph | undefined>(undefined);

    const [showGrades, setShowGrades] = useState(false);
    const [hoveredParagraph, setHoveredParagraph] = useState<Paragraph | undefined>(undefined);

    const [hovered, setHovered] = useState(false);
    const [paragraphGraded, setParagraphGraded] = useState<boolean>(false);
    const [userGrade, setUserGrade] = useState<number | undefined>(undefined);

    const checkIfUserGraded = (paragraph: Paragraph) => {
        setUserGrade(undefined);
        setParagraphGraded(false);
        if (paragraph.isApproved) {
            paragraph.grades!.map((grade) =>
                (grade.discordId === user!.discordId) ? (setUserGrade(grade.grade!), setParagraphGraded(true)) : (setUserGrade(undefined), setParagraphGraded(false)));
        } else {
            const edition = getParagraphCurrentEdition(paragraph);
            // console.log(edition.grades)
            const grade = edition.grades?.filter((grade) =>
                grade.discordId === user!.discordId)
            if (grade && grade!.length > 0) {
                setUserGrade(grade![0].grade);
                setParagraphGraded(true);
            } else {
                setUserGrade(undefined);
                setParagraphGraded(false)
            }
            // (grade.discordId === user!.discordId) ? (console.log('gr'),setUserGrade(grade.grade!), setParagraphGraded(true)) : (setUserGrade(undefined), setParagraphGraded(false)));
        }
    }

    if (isParagraphUpdated) {
        const par: Paragraph = Object.assign(isParagraphUpdated);
        console.log(par)
        // setActiveParagraph(par);
        // toggleEditionPanel(isParagraphUpdated);
        // setParagraphGraded(false);
        // checkIfUserGraded(isParagraphUpdated);
        // setShowGrades(false)
        // localStorage.removeItem('edition');
        // localStorage.setItem('paragraph', JSON.stringify(isParagraphUpdated))
        setIsParagraphUpdated(undefined)
    }


    const handleParagraphClick = (paragraph: Paragraph) => {
        if (activeParagraph == paragraph) {
            setActiveParagraph(undefined);
            toggleEditionPanel(paragraph);
            setActiveCommentingProcess(false);
            checkIfUserGraded(activeParagraph);
            setParagraphGraded(false);
            localStorage.removeItem('paragraph');
            localStorage.removeItem('edition');
            // console.log(localStorage.getItem('paragraph'))
            setShowGrades(false)
        } else {
            setActiveParagraph(paragraph);
            toggleEditionPanel(paragraph);
            setParagraphGraded(false);
            checkIfUserGraded(paragraph);
            setShowGrades(false)
            localStorage.removeItem('edition');
            localStorage.setItem('paragraph', JSON.stringify(paragraph))

        }
    }
    const handleGradeClick = () => {
        showGrades ? setShowGrades(false) : setShowGrades(true)
    }

    const handleHovered = (hovered: boolean, paragraph: Paragraph | undefined) => {
        setHovered(hovered);
        setHoveredParagraph(paragraph)
    }

    const getParagraphCurrentEdition = (paragraph: Paragraph) => {
        return paragraph.editions
            .sort((a: ParagraphEdition, b: ParagraphEdition) => {
                if (a.sysRank > b.sysRank) return -1;
                if (a.sysRank < b.sysRank) return 1;
                if (a.id > b.id) return -1;
                if (a.id < b.id) return 1;
                return 0;
            })[0]
    }

    const [isScrolled, setIsScroller] = useState(false);
    const grades = Array.from({ length: 10 }, (_, i) => i + 1);
    const myRef = useRef<null | HTMLDivElement>(null);

    const executeScroll = () => myRef.current?.scrollIntoView()

    useEffect(() => {
        executeScroll();
    }, [html]);


    return (
        <>
            {paragraphs?.map((paragraph) => (
                <>
                    <ContainerStyle style={{ scale: ((hoveredParagraph == paragraph) ? ('101%') : ('100%')), cursor: 'pointer', background: (activeParagraph == paragraph) ? ('#e5decf') : ('#fff') }} key={"paragraph-" + paragraph.id}
                        onClick={() => {
                            handleParagraphClick(paragraph);
                        }}
                        onMouseEnter={() => handleHovered(true, paragraph)} onMouseLeave={() => handleHovered(false, undefined)}>

                        <Markdown content={paragraph.isApproved ? paragraph.content : getParagraphCurrentEdition(paragraph).content} />

                        {((activeParagraph == paragraph)) && (
                            <ContainerStyle style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ width: 'max-content' }}>
                                    {/* ( */}
                                    {/* <><b>Your grade: </b><i>{userGrade ? userGrade : '-'}</i> || <b>Rating: </b><i>{getParagraphCurrentEdition(paragraph).sysRank}</i></>) : ( */}

                                    <><b>Status:</b> {paragraph.isApproved ? <>APPROVED✅</> : <>NOT APPROVED ❌</>}  <b>Rating: </b><i>{Number(getParagraphCurrentEdition(paragraph).sysRank).toFixed(3)}/10</i>  {getParagraphCurrentEdition(paragraph).grades && getParagraphCurrentEdition(paragraph).grades!.length > 0 && <SubmitButtonStyle style={{ cursor: 'pointer', color: 'black', background: '#bbbbbb', borderRadius: '5x' }} onClick={(e) => (e.stopPropagation(), handleGradeClick())}>check grades</SubmitButtonStyle>}</>
                                    {showGrades && <ContainerStyle style={{ display: 'grid' }} onClick={e => e.stopPropagation()}>
                                        {getParagraphCurrentEdition(paragraph).grades?.map((grade) => {
                                            return (
                                                <label> User <b>({grade.username})</b> set {grade.grade} <b>RIT</b> to competence <b>#{grade.competenceName}</b></label>
                                            )
                                        })}
                                    </ContainerStyle>}
                                    {/* ) */}
                                </div>
                                {/* <div>{JSON.stringify(getParagraphCurrentEdition(paragraph).grades)}</div> */}
                                {!paragraph.isApproved &&
                                    <div>
                                        <SubmitButtonStyle style={{ margin: 0, height: 'auto', marginTop: '10px', background: '#70e1c8' }}
                                            onClick={(e) =>
                                            (e.stopPropagation(),
                                                setActiveCommentingProcess(true),
                                                toggleCommentPanel(paragraph),
                                                setCommentCode('paragraph'))
                                            }>
                                            COMMENTS
                                        </SubmitButtonStyle>
                                    </div>
                                }
                            </ContainerStyle>
                        )}
                    </ContainerStyle >

                    <div>
                        {newParagraph == (paragraph.id).toString() && activeEditionProcess && html != '' && entityCode == 'paragraph' &&
                            <ContainerStyle ref={myRef} key={"new-paragraph-below-" + paragraph.id} style={{ marginTop: '40px', marginBottom: '40px', background: '#fff', }}>
                                <ContainerStyle style={{ background: '#70e1c8' }}><span>Suggested paragraph: </span>
                                </ContainerStyle>
                                <Markdown content={html} />
                                <div style={{ display: 'flex', justifyContent: 'right', marginRight: '20px' }}>
                                    <SubmitButtonStyle onClick={() => (
                                        handleNewParagraph(html, paragraph, user!), setActiveEditionProcess(false)
                                    )}>
                                        SUBMIT NEW PARAGRAPH
                                    </SubmitButtonStyle>
                                </div>
                            </ContainerStyle>

                        }
                    </div>
                </>
            )
            )}
            {/* 
            <div>
                {newParagraph == (paragraph.id).toString() && activeEditionProcess && html != '' && entityCode == 'paragraph' &&
                    <ContainerStyle key={"new-paragraph-below-" + paragraph.id} style={{ marginTop: '40px', marginBottom: '40px', background: '#fff', }}>
                        <ContainerStyle style={{ background: '#70e1c8' }}><span>Suggested paragraph: </span>
                        </ContainerStyle>
                        <Markdown content={html} />
                        <div style={{ display: 'flex', justifyContent: 'right', marginRight: '20px' }}>
                            <SubmitButtonStyle onClick={() => (
                                handleNewParagraph(html, paragraph, user!), setActiveEditionProcess(false)
                            )}>
                                SUBMIT NEW PARAGRAPH
                            </SubmitButtonStyle>
                        </div>
                    </ContainerStyle>

                }
            </div> */}

        </>)
}