import { useContext, useEffect, useState } from 'react';
import { getMutualGuilds, getTeams, getUser, getUsers, postNewProject, postNewTeam } from '../api';
import { PartialGuild, ProjectDetails, Team, TeamDetails } from '../types';
import { useNavigate } from 'react-router-dom';
import { ProjectContext } from '../contexts/ProjectContext';

export function useFetchTeams() {
    const [usernames, setUsernames] = useState<string[]>([]);
    const [teams, setTeam] = useState<Team[]>();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const navigate = useNavigate();
    const { updateProject } = useContext(ProjectContext);

    useEffect(() => {
        setLoading(true);
        getTeams()
            .then(({ data }) => {
                getUsers()
                    .then(({ data }) => {

                        setUsernames(data);
                    })
                    .catch((err) => {
                        setError(err);
                    })
                setTeam(data);
            })
            .catch((err) => {
                setError(err);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    // useEffect(() => {
    //     getUsers()
    //         .then(({ data }) => {
    //             setUsernames(data as string[]);
    //         })
    //         .catch((err) => {
    //             setError(err);
    //         })
    // }, []);

    const handleNewProject = (project: ProjectDetails, id: number) => {
        setLoading(true);
        postNewProject(project, id)
            .then(
                (project) => {
                    updateProject(project)
                    navigate(`/documents/${project.id}`)
                }).finally(() => {
                    setLoading(false)
                })

    }

    const handleNewTeam = (team: TeamDetails) => {
        setLoading(true);

        // console.log('team' + JSON.stringify(team))
        postNewTeam(team)
            .then(() => {
                getTeams()
                    .then(({ data }) => {
                        setTeam(data);
                    })
                    .catch((err) => {
                        setError(err);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            })
            .catch((err) => {
                setError(err);
            });
    }

    return { usernames, teams, loading, error, handleNewTeam, handleNewProject };
}
