import { ChangeEvent, useMemo, useRef, useState } from 'react';
import { DocCanvasStyle, EditionPanelStyle, EditionPanelToggledStyle, InputField, NavBarStyle, ContainerStyle, SubmitButtonStyle, AddEntityButtonStyle } from '../../utils/styles/index.styles';
import { Comment, Doc, Paragraph, ParagraphEdition, User } from '../../utils/types';
import { Markdown } from '../document/Markdown';
import { Marked } from '@ts-stack/markdown';
import { InputFieldMD } from '../menuItems/InputFieldMD';
import { useFetchParagraphEditions } from '../../utils/hooks/useFetchParagraphEditions';
import { useFetchParagraphComments } from '../../utils/hooks/useFetchParagraphComments';
import { useFetchUser } from '../../utils/hooks/useFetchUser';
import { useFetchEditionComments } from '../../utils/hooks/useFetchEditionComments';
import React from 'react';

type Props = {
    edition: ParagraphEdition;
    toggleHeight: string;
    handleFullToggle: (full: boolean, newPargraphBelow: string, entityCode: string) => void;
    fullToggle: boolean;
    setHtml: (html: string) => void;
    setActiveEditionProcess: (active: boolean) => void;
};

export const EditionCommentPanel = ({ edition, toggleHeight, handleFullToggle, fullToggle, setHtml, setActiveEditionProcess }: Props) => {
    const [commentContent, setCommentContent] = useState('');
    const { edcomments, handleNewEditionComment } = useFetchEditionComments(edition);
    const myRef = useRef<null | HTMLDivElement>(null);

    const executeScroll = () => myRef.current!.scrollIntoView()

    const { user } = useFetchUser();
    return (
        <EditionPanelStyle style={{ height: toggleHeight }}>

            <EditionPanelToggledStyle onClick={() => { setActiveEditionProcess(true) }}>

                <ContainerStyle style={{ display: 'flex', flexDirection: 'column', maxHeight: '28vh', padding: '4px', background: '#BBBBBB' }}>

                    <div key={'comments'} style={{ display: 'flex', flexDirection: 'column', rowGap: '10px', height: '100%', padding: '4px', overflowY: 'auto' }}>
                        {edcomments?.sort((a: Comment, b: Comment) =>
                            a.id > b.id ? -1 : 1
                        ).map((comment) => (
                            <ContainerStyle key={"comment_pp" + comment.id} style={{ height: 'max-content' }}>
                                <ContainerStyle key={"comment_par_" + comment.id} style={{ height: 'max-content' }}>
                                    {comment.content}
                                </ContainerStyle>
                                <b>author: </b><i>{comment.creatorName}</i>
                            </ContainerStyle>
                        ))}
                    </div>

                    <div style={{ display: 'grid', boxShadow: '0 1px 6px rgba(0, 0, 0, 0.50)', background: '#fff', borderRadius: '10px', gridTemplateColumns: '10fr 1fr' }}>
                        <textarea style={{
                            height: '100%', boxSizing: 'border-box', padding: '10px', border: 'none', resize: 'none', width: '100%', borderRadius: '10px'
                        }}
                            placeholder="Enter your comment here"
                            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setCommentContent(e.target.value)}
                        />

                        <SubmitButtonStyle style={{ marginRight: '10px', marginLeft: '10px' }}
                            onClick={() => (commentContent != '' && handleNewEditionComment(commentContent, edition, user!))}
                        >Comment</SubmitButtonStyle>
                    </div>

                </ContainerStyle>

                <ContainerStyle style={{ background: '#bbbbbb' }}>
                    <div style={{ height: '100%', width: '100%', }}>
                        <ContainerStyle style={{ height: '10%', background: '#70e1c8' }}>
                            <h5 style={{ margin: '2px' }}>STATISTICS</h5>
                        </ContainerStyle>
                        <ContainerStyle style={{ display: 'flex', flexDirection: 'column', marginTop: '2%', height: 'auto' }}>
                            <span style={{ marginTop: '4px' }}>
                                Rating: <b>0</b>
                            </span>
                            <span style={{ marginTop: '4px' }}>
                                Approved paragraphs: <b>0</b>
                            </span>
                        </ContainerStyle>
                    </div>
                    {/* <button onClick={() => { }}> submit </button> */}
                </ContainerStyle>
            </EditionPanelToggledStyle>

        </EditionPanelStyle >
    )
};
