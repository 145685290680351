import { getIconURL } from '../../../utils/helpers';
import { Button, GuildMenuItemStyle, SubmitButtonStyle } from '../../../utils/styles/index.styles';
import { Doc, PartialGuild } from '../../../utils/types';
import { ReactComponent as File } from '../../../assets/file.svg';
import React from 'react';
type Props = {
  doc: Doc;
  toggleAbout: (doc: Doc) => void
};

export const DocMenuItem = ({ doc, toggleAbout }: Props) => (
  <GuildMenuItemStyle style={{ cursor: 'pointer', marginTop: '0px', padding: '10px', width: 'auto', height: '80px', flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
    <div style={{ height: '100%', display: 'flex', flexDirection: 'row' }}>
      <File style={{ width: 'auto', margin: 'auto', height: '50%' }} />
      <label style={{ margin: 'auto', color: '#fff' }}>{doc.name}</label>
    </div>
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'right' }} >
      <SubmitButtonStyle style={{ background: '#f2ef42 ', color: 'black' }} onClick={(e) => (e.stopPropagation(), toggleAbout(doc))}>ABOUT</SubmitButtonStyle>
      {!doc.isFinalised ?
        (<p style={{ display: 'flex', margin: '0px', justifyContent: 'right' }} >
        </p>) :
        (<p style={{ display: 'flex', margin: '0px', justifyContent: 'right' }} ><b style={{ color: '#70e1c8' }}>FINALIZED ✅</b></p>)}


    </div>
  </GuildMenuItemStyle>
);
