import { useContext, useEffect, useMemo, useState } from "react";
import { BlockHeading, ContainerStyle, DocCanvasStyle, DocumentContainerStyle, EditionPanelStyle, EditionPanelToggledStyle, Flex, Page, SubmitButtonStyle } from "../utils/styles/index.styles";
import { useFetchDocumentParagraphs } from "../utils/hooks/useFetchDocumentParagraphs";
import { BarLoader, MoonLoader } from "react-spinners";
import { Doc, Paragraph, ParagraphEdition, User } from "../utils/types";
import { ParagraphContext } from "../utils/contexts/ParagraphContext";
import { Markdown } from "../components/document/Markdown";
import { InputFieldMD } from "../components/menuItems/InputFieldMD";
import { useFetchUser } from "../utils/hooks/useFetchUser";
import { useFetchParagraphEditions } from "../utils/hooks/useFetchParagraphEditions";
import { ParagraphContainer } from "../components/paragraph/ParagraphContaner";
import { EditionsContainer } from "../components/edition/EditionsContainer";
import { EditionPanel } from "../components/edition/EditionPanel";
import { ParagraphCommentPanel } from "../components/comment/ParagraphCommentPanel";
import { EditionCommentPanel } from "../components/comment/EditionCommentPanel";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Spinner } from "../components/Spinner";



export const DocumentPage = () => {
    const { user } = useFetchUser();
    const doc: Doc = JSON.parse(localStorage.getItem('doc')!);
    const [searchParams, setSearchParams] = useSearchParams();
    let page = 1;
    const { downloadDoc, paragraphs, currentPage, setCurrentPage, error, loading, isFinalised, handleFinalization, handleNewParagraph, handleGradeEd, update } = useFetchDocumentParagraphs(doc, page);
    // const res = searchParams.get('page')
    // if (res != null && !isNaN(+res)) {
    //     if (Number.isInteger(Number(res))) {
    //         page = parseInt(res)
    //         setCurrentPage(page)
    //     }
    // }
    // console.log(page)


    const [isgraded, setIsgraded] = useState<boolean>(false);
    const [gradedComp, setGradedComp] = useState<string[]>(['']);
    // const paragraph: Paragraph = JSON.parse(localStorage.getItem('paragraph')!);

    const [ed, setEdition] = useState<ParagraphEdition | undefined>(undefined);
    const [commentCode, setCommentCode] = useState('');
    const [panel, setPanel] = useState(doc.isFinalised);
    const [comment, setComment] = useState(false);
    const [editionComment, setEditionComment] = useState(false);
    const [activeCommentingProcess, setActiveCommentingProcess] = useState(false);

    // const { loading } = useFetchDocumentParagraphs(doc);

    const [activeParagraph, setActiveParagraph] = useState<Paragraph | undefined>(undefined);
    const [activeEditionProcess, setActiveEditionProcess] = useState(false);
    const [html, setHtml] = useState<string>('');
    const [fullToggle, setFullToggle] = useState(doc.isFinalised);
    const [newParagraph, setNewParagraph] = useState('');
    const [entityCode, setEntityCode] = useState('');
    const [commentingParagraph, setCommentingParagraph] = useState<Paragraph | undefined>(undefined)
    const [commentingEdition, setCommentingEdition] = useState<ParagraphEdition | undefined>(undefined)
    const [isEditionGraded, setIsEditionGraded] = useState<boolean>(false)
    const [isEditionUpdated, setIsEditionUpdated] = useState<ParagraphEdition | undefined>(undefined)
    const [isParagraphUpdated, setIsParagraphUpdated] = useState<Paragraph | undefined>(undefined)


    // console.log('html' + html)

    const toggleEditionPanel = (paragraph: any) => {
        setActiveCommentingProcess(false);
        setCommentingEdition(undefined)
        setCommentingParagraph(undefined)
        setIsgraded(false)
        setGradedComp([''])
        if (activeParagraph === paragraph) {
            setPanel(doc.isFinalised);
            setFullToggle(doc.isFinalised);
            setActiveParagraph(undefined);
            setActiveEditionProcess(false);
            setNewParagraph('')
            setEntityCode('')
            setComment(false)
            setEditionComment(false);
        }
        else {
            setPanel(!!paragraph);
            setFullToggle(false);
            setActiveEditionProcess(false);
            setActiveParagraph(paragraph);
            setNewParagraph('')
            setEntityCode('')
            setComment(false)
            setEditionComment(false);
        }

    };

    const toggleCommentPanel = (paragraph: Paragraph) => {
        if (commentingParagraph == paragraph) {
            setComment(false)
            setPanel(doc.isFinalised)
            setFullToggle(doc.isFinalised)
            setActiveCommentingProcess(false)
            setCommentingParagraph(undefined)
        } else {
            setCommentingParagraph(paragraph)
            setComment(true)
            setPanel(true)
            setFullToggle(true)
            setActiveCommentingProcess(true)
        }
    };

    const toggleEditionCommentPanel = (edition: ParagraphEdition) => {

        if (commentingEdition == edition) {
            setEditionComment(false)
            setPanel(doc.isFinalised)
            setFullToggle(doc.isFinalised)
            setActiveCommentingProcess(false)
            setCommentingEdition(undefined)
        } else {
            setCommentingEdition(edition)
            setEditionComment(true)
            setPanel(true)
            setFullToggle(true)
            setActiveCommentingProcess(true)
        }
    }


    const fullToggleHandle = (full: boolean, newPargraphBelow: string, ec: string) => {
        setFullToggle(full);
        setNewParagraph(newPargraphBelow)
        setEntityCode(ec)
        // console.log(entityCode)
    }

    const calculateHeight = (isFullToggle: boolean, isPanel: boolean): string => {
        if (doc.isFinalised) { return '65vh' };
        if (isFullToggle && isPanel) {
            return "55vh";
        }
        return isPanel ? "77vh" : "85vh";
    }

    const calculateToggleHeight = (isFullToggle: boolean, isPanel: boolean): string => {
        if (doc.isFinalised) { return '18vh' };
        if (isFullToggle && isPanel) {
            return "28vh";
        }
        return isPanel ? "7vh" : "0%";
    }

    const height = useMemo(() => calculateHeight(fullToggle, panel), [fullToggle, panel]);
    let totalPages = doc.pageCount;
    if (totalPages === 0) totalPages = 1;
    const changePage = (direction: number) => {
        let newPage = currentPage + direction
        // => {
        //     const newPage = page + direction;
        //     if (newPage < 1) return 1;
        //     if (newPage > totalPages) return totalPages;
        //     return newPage;
        if (newPage < 1) newPage = 1;
        if (newPage > totalPages) newPage = totalPages;
        setCurrentPage(newPage);
    };

    const goToPage = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const form = event.target as HTMLFormElement;
        const pageInput = form.pageInput.value;
        const pageNumber = parseInt(pageInput, 10);
        if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        } else {
            alert(`Please enter a valid page number between 1 and ${totalPages}`);
        }
        form.reset();
    };


    return (
        <Page >

            <DocumentContainerStyle style={{ height: height }} >

                {loading ? (
                    <Flex justifyContent="center">
                        <Spinner children={<BarLoader color="white" />} />
                    </Flex>
                ) : (
                    <><DocCanvasStyle>
                        <BlockHeading>
                            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}>
                                <div></div>
                                <h3 style={{ margin: '3px' }}>PARAGRAPHS</h3>
                                {/* {isFinalised ? <SubmitButtonStyle style={{ margin: '0px' }}>yes</SubmitButtonStyle> : <SubmitButtonStyle>no</SubmitButtonStyle>} */}
                                <div style={{}}>
                                    <div style={{ display: 'flex', flexDirection: 'row', background: '#f2ef42', borderRadius: '5px', columnGap: '10px', width: 'max-content' }}>
                                        <div style={{ marginLeft: '5px' }}>
                                            <SubmitButtonStyle onClick={() => changePage(-1)} style={{ padding: '5px' }}>{'<<'}</SubmitButtonStyle>
                                            <span id="pageInfo"> {currentPage} of {totalPages} </span>
                                            <SubmitButtonStyle onClick={() => changePage(1)} style={{ padding: '5px' }}>{'>>'}</SubmitButtonStyle>
                                        </div>
                                        <form onSubmit={goToPage} style={{ margin: '0px', marginRight: '5px' }}>
                                            <input type="number" min={1} max={totalPages} name="pageInput" placeholder="Page" style={{ width: '4vw', marginRight: '10px' }} />
                                            <SubmitButtonStyle type="submit" style={{ padding: '5px' }}>Go</SubmitButtonStyle>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </BlockHeading>

                        <DocCanvasStyle style={{ overflowY: 'scroll', color: 'none' }}>
                            <ParagraphContainer
                                doc={doc}
                                html={html}
                                activeEditionProcess={activeEditionProcess}
                                setActiveEditionProcess={setActiveEditionProcess}
                                toggleEditionPanel={toggleEditionPanel}
                                toggleCommentPanel={toggleCommentPanel}
                                toggleEditionCommentPanel={toggleEditionCommentPanel}
                                newParagraph={newParagraph}
                                entityCode={entityCode}
                                setCommentCode={setCommentCode}
                                setActiveCommentingProcess={setActiveCommentingProcess}
                                paragraphs={paragraphs!}
                                handleNewParagraph={handleNewParagraph}
                                update={update}
                                handleGrade={handleGradeEd}
                                setIsParagraphUpdated={setIsParagraphUpdated}
                                isParagraphUpdated={isParagraphUpdated}
                            />
                        </DocCanvasStyle>
                    </DocCanvasStyle>
                        <DocCanvasStyle>
                            <BlockHeading>
                                <h3 style={{ margin: '3px' }}>EDITIONS FOR SELECTED PARAGRAPH</h3>
                            </BlockHeading>
                            {activeParagraph != undefined && (
                                /* <DocCanvasStyle style={{ overflowY: 'scroll', color: 'none' }}> */
                                <EditionsContainer
                                    setFullToggle={setFullToggle}
                                    html={html}
                                    paragraph={activeParagraph!}
                                    setEdition={setEdition}
                                    activeEditionProcess={activeEditionProcess}
                                    setActiveEditionProcess={setActiveEditionProcess}
                                    entityCode={entityCode}
                                    toggleEditionCommentPanel={toggleEditionCommentPanel}
                                    setCommentCode={setCommentCode}
                                    setActiveCommentingProcess={setActiveCommentingProcess}
                                    paragraphs={paragraphs!}
                                    handleNewParagraph={handleNewParagraph}
                                    handleGradeEd={handleGradeEd}
                                    update={update}
                                    doc={doc}
                                    isEditionGraded={isEditionGraded}
                                    setIsEditionGraded={setIsEditionGraded}
                                />

                            )}
                        </DocCanvasStyle>
                    </>
                )}

            </DocumentContainerStyle>
            {!doc.isFinalised ? (
                !activeCommentingProcess && panel && activeParagraph && (
                    <EditionPanel
                        isgraded={isgraded}
                        setIsgraded={setIsgraded}
                        gradedComp={gradedComp}
                        setGradedComp={setGradedComp}
                        entityCode={entityCode}
                        paragraph={activeParagraph!}
                        toggleHeight={calculateToggleHeight(fullToggle, panel)}
                        fullToggle={fullToggle}
                        handleFullToggle={fullToggleHandle}
                        setHtml={setHtml}
                        handleGrade={handleGradeEd}
                        update={update}
                        setActiveEditionProcess={setActiveEditionProcess}
                        handleFinalization={handleFinalization}
                        doc={doc}
                        setIsEditionGraded={setIsEditionGraded}
                        setIsParagraphUpdated={setIsParagraphUpdated}
                    // setEditions={setEditions}
                    />
                )
            ) : (
                <EditionPanelStyle style={{ height: calculateToggleHeight(fullToggle, panel) }}>

                    <ContainerStyle style={{ height: 'auto', display: 'flex', justifyContent: 'space-between', background: '#70e1c8' }}>
                        <h5 style={{ margin: '2px' }}>REWARDS for initial deposit {doc.deposit}SOL</h5>
                        <SubmitButtonStyle style={{ background: '#512DA8', color: '#fff', marginTop: '0px', marginBottom: '0px', marginRight: '10px', marginLeft: '10px' }}
                            onClick={() => (downloadDoc(paragraphs!))}
                        >DOWNLOAD FINALISED DOCUMENT</SubmitButtonStyle>
                    </ContainerStyle>

                    <div style={{ display: 'flex', padding: '5px', flexDirection: 'column', marginTop: '7px', overflowY: 'scroll', height: '12vh' }}>
                        {doc.rewards!.map((data) => (
                            <ContainerStyle>

                                <b>Wallet:</b> {data.wallet}<br />
                                <b>Sum:</b> {data.sum} <b>SOL</b><br />
                                {/* <b>Signature:</b> {data.signature} */}
                            </ContainerStyle>
                        ))}
                    </div>
                </EditionPanelStyle>)
            }

            {
                activeCommentingProcess && commentCode === 'paragraph' &&
                <ParagraphCommentPanel
                    paragraph={commentingParagraph!}
                    toggleHeight={calculateToggleHeight(fullToggle, panel)}
                    fullToggle={fullToggle}
                    handleFullToggle={fullToggleHandle}
                    setHtml={setHtml}
                    setActiveEditionProcess={setActiveEditionProcess}
                // setEditions={setEditions}
                />
            }
            {
                activeCommentingProcess && commentCode === 'edition' && (
                    <EditionCommentPanel
                        edition={commentingEdition!}
                        toggleHeight={calculateToggleHeight(fullToggle, panel)}
                        fullToggle={fullToggle}
                        handleFullToggle={fullToggleHandle}
                        setHtml={setHtml}
                        setActiveEditionProcess={setActiveEditionProcess}
                    // setEditions={setEditions}
                    />
                )
            }
            {
                activeCommentingProcess && commentCode === 'grade' && (
                    <><p></p></>
                )
            }

        </Page >
    )
}