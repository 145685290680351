import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { getDocumentParagraphs, getEditionComments, getParagraphComments, postNewEditionComment, postNewParagraph, postNewParagraphComment, postNewParagraphEdition } from '../api';
import { Comment, CommentDetails, Doc, Paragraph, ParagraphDetails, ParagraphEdition, ParagraphEditionDetails, User } from '../types';


export function useFetchParagraphComments(paragraph: Paragraph) {

    const [comments, setComments] = useState<Comment[]>();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>();

    const handleNewParagraphComment = (content: string, paragraph: Paragraph, user: User) => {
        try {
            const commentDetails: CommentDetails = {
                creatorId: user.discordId,
                creatorName: user.username,
                content: content,
                paragraph: paragraph,

            }
            postNewParagraphComment(commentDetails).then(setComments);
        } catch (e) {

        }
    };

    useEffect(() => {
        setLoading(true);
        getParagraphComments(paragraph.id.toString())
            .then(response => {
                setComments(response.data);
            })
            .catch(err => {
                console.error(err);
                setError(err);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [paragraph.id]);
    return { comments, loading, error, handleNewParagraphComment };
}
