import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { getDocumentParagraphs, getEditionComments, postNewEditionComment, postNewParagraph, postNewParagraphEdition } from '../api';
import { Comment, CommentDetails, Doc, Paragraph, ParagraphDetails, ParagraphEdition, ParagraphEditionDetails, User } from '../types';


export function useFetchEditionComments(edition: ParagraphEdition) {
    const [edcomments, setComments] = useState<Comment[]>();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>();



    const handleNewEditionComment = (content: string, edition: ParagraphEdition, user: User) => {
        try {
            const commentDetails: CommentDetails = {
                creatorId: user.discordId,
                creatorName: user.username,
                content: content,
                edition: edition,
            }
            postNewEditionComment(commentDetails).then(setComments);
        } catch (e) {

        }
    };

    useEffect(() => {
        setLoading(true);
        getEditionComments(edition.id.toString())
            .then(response => {
                setComments(response.data);

            })
            .catch(err => {
                console.error(err);
                setError(err);
            })
            .finally(() => {

                setLoading(false);

            });

    }, [edition.id]);

    return { edcomments, loading, error, handleNewEditionComment };
}
