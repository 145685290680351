import { ChangeEvent } from "react";
import { Paragraph, ParagraphEdition } from "../../utils/types";
import "./DraftEditor.css";
import { Editor } from "@tinymce/tinymce-react";

interface Props {
    onHTMLChange: (html: string) => void;
    paragraph: Paragraph | ParagraphEdition;
    setActiveEditionProcess: (bool: boolean) => void
}


export const InputFieldMD = ({ setActiveEditionProcess, onHTMLChange, paragraph }: Props) => {
    const handleEditorChange = (content: any, editor: any) => {
        // console.log("Content was updated:", content);
        onHTMLChange(content)
        setActiveEditionProcess(true)
    };



    return (
        <>
            <div style={{ height: '100%', borderRadius: '20px' }}>
                {/* @ts-ignore*/}
                < Editor
                    apiKey="p0kbvfpramde1d3xzluv0xg3bpuubcgwv84yhk4cvl2oz6l4"
                    // initialValue="<p>This is the initial content of the editor</p>"
                    init={{
                        skin: "snow",
                        icons: "thin",
                        placeholder: "Type your text here...",
                        borderRadius: '10px',
                        height: '100%',
                        menubar: true,
                        resize: false,
                        margin: '5px',
                        font_css: 'Times',
                        plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen textcolor ",
                            "insertdatetime media table paste code help wordcount"
                        ],
                        textcolor_rows: "4",
                        toolbar: "undo redo | styleselect | fontsizeselect | fontselect | code | bold italic | alignleft aligncenter alignright alignjustify | outdent indent "
                    }}
                    onEditorChange={handleEditorChange} />
            </div>
        </>
        //     <div>
        //         {/* // <Editor
        //   //   apiKey="qagffr3pkuv17a8on1afax661irst1hbr4e6tbv888sz91jc"
        //   //   initialValue="Once upon a time..."
        //   //   // plugins="wordcount"
        //   //   init={{
        //   //     skin: "snow",
        //   //     icons: "thin",
        //   //     height: 500,
        //   //     menubar: false,
        //   //     plugins: ["wordcount"],
        //   //     toolbar:
        //   //       "undo redo | styleselect | fontsizeselect | bold italic | alignleft aligncenter alignright alignjustify | outdent indent"
        //   //   }}
        //   // /> */}
        //         <Editor
        //             apiKey="qagffr3pkuv17a8on1afax661irst1hbr4e6tbv888sz91jc"
        //             // initialValue="<p>This is the initial content of the editor</p>"
        //             init={{
        //                 skin: "snow",
        //                 icons: "thin",
        //                 placeholder: "Ask a question or post an update...",

        //                 height: 200,
        //                 menubar: true,
        //                 plugins: [
        //                     "advlist autolink lists link image charmap print preview anchor",
        //                     "searchreplace visualblocks code fullscreen textcolor ",
        //                     "insertdatetime media table paste code help wordcount"
        //                 ],
        //                 textcolor_rows: "4",

        //                 toolbar:
        //                     "undo redo | styleselect | fontsizeselect| code | bold italic | alignleft aligncenter alignright alignjustify | outdent indent "
        //             }}
        //             onEditorChange={handleEditorChange}

        //         // toolbar="code"
        //         />

        //         <input />
        //     </div>
        // <textarea style={{
        //     height: '100%', boxSizing: 'border-box', padding: '10px', border: 'none', resize: 'none', width: '100%', borderRadius: '10px'
        // }}
        //     placeholder="Enter .MD code here"
        //     onChange={(e: ChangeEvent<HTMLTextAreaElement>) => (onHTMLChange(e.target.value))}
        // />
    );
};
