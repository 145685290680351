import { useContext, useState } from 'react';
import { getIconURL } from '../../utils/helpers';
import { BlockHeading, Button, CatButtonStyle, CategoryContainerStyle, Container, ContainerStyle, GuildMenuItemStyle, SubmitButtonStyle } from '../../utils/styles/index.styles';
import { PartialGuild, PartialGuildChannel, Project, Team, } from '../../utils/types';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { CreateProjectModal } from '../project/CreateProject';
import { ProjectContext } from '../../utils/contexts/ProjectContext';
import { AboutTeamModal } from './AboutTeamModal';
type Props = {
  team: Team;
  handleNewProject: any;
};

export const TeamMenuItem = ({ team, handleNewProject }: Props) => {
  // console.log("team " + team.id)
  const navigate = useNavigate();
  const { updateProject } = useContext(ProjectContext);

  const [categoriesShown, setCategoriesShown] = useState(true);

  const handleClick = (project: Project) => {
    updateProject(project);
    // console.log(localStorage.getItem('project'))
    navigate(`/documents/${project.id}`);

  };
  // console.log(team.name)
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {

    const selectedCategoryId = parseInt(event.target.value);
    const selectedCategory = team.projects!.find(project => project.id === selectedCategoryId);
    if (selectedCategory) {
      handleClick(selectedCategory);
    }
  };
  const toggleModal = () => setIsModalOpen(!isModalOpen);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false);
  const toggleAboutModal = () => setIsAboutModalOpen(!isAboutModalOpen);


  return (

    <><CreateProjectModal team={team} isOpen={isModalOpen} onClose={toggleModal} handleNewProject={handleNewProject} />
      <AboutTeamModal team={team} isOpen={isAboutModalOpen} onClose={toggleAboutModal} /><>
        <GuildMenuItemStyle style={{ height: "max-content" }} onMouseEnter={() => (setCategoriesShown(true))} onMouseLeave={() => (setCategoriesShown(true))}>
          {/* <BlockHeading style={{ cursor: 'pointer', margin: '0px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}> */}
          <div style={{ margin: '30px' }}>
            <img
              src={team.photo?.url}
              alt={team.name}
              width={125}
              height={125}
              style={{ display: 'flex', justifyContent: 'center', borderRadius: '20%', border: '2px solid #70e1c8' }} />
          </div>

          <h2 style={{ margin: '0px', height: '10vh', }}>{team.name}</h2>

          <h3 style={{ margin: '0px', height: '15vh', overflow: 'auto' }}><i>{team.description}</i></h3>

          {/* </BlockHeading> */}
          {categoriesShown && (
            <>
              <h3 style={{ margin: 0 }}>Projects:</h3>
              {/* <CategoryContainerStyle style={{ background: '#bbbbbb',  rowGap:'5px', overflowY: 'scroll'}}>
              {guild.categories.map((category) => (
                <CatButtonStyle key={'category_' + category.id} onClick={() => (handleClick(category))}>{category.name}</CatButtonStyle>
              ))}
            </CategoryContainerStyle> */}
              <CategoryContainerStyle style={{ background: '#8b8a8a', display: 'flex', overflowY: 'scroll', width: '100%', justifyContent: 'center' }}>
                <select onChange={handleChange} style={{ color: '#70e1c8', cursor: 'pointer', background: '#333333', borderRadius: '10px', width: '80%', padding: '10px', margin: '5px 0' }}>
                  <option value="">Select a project</option>
                  {team.projects?.map(project => (
                    <option key={'category_' + project.id} value={project.id}>
                      {project.name}
                    </option>
                  ))}
                </select>
              </CategoryContainerStyle>
            </>)}

          <div style={{ gridTemplateColumns: '2fr 1fr', display: 'grid', }}>
            <SubmitButtonStyle style={{ background: '#70e1c8', margin: '20px', color: 'black' }} onClick={(e) => { e.stopPropagation(); toggleModal() }} >CREATE NEW PROJECT</SubmitButtonStyle>
            <SubmitButtonStyle style={{ background: '#f2ef42', margin: '20px', color: 'black' }} onClick={(e) => { e.stopPropagation(); toggleAboutModal() }} >ABOUT</SubmitButtonStyle>
          </div>
        </GuildMenuItemStyle>
      </></>
  )
};
