import React, { ChangeEvent, FormEvent, useRef, useState } from 'react';
import './NewDocumentModal.css'; // Assume basic styling here
import { SubmitButtonStyle } from '../../../utils/styles/index.styles';
import { Competence, DiscordUserType, Doc, Project, Team, User } from '../../../utils/types';
import PLUS from '../../../assets/plus.png';
import { useFetchUser } from '../../../utils/hooks/useFetchUser';
import doc_photo from '../../../assets/upload_doc.jpg'
import './Upload.css';

interface Props {
    project: Project
    isOpen: boolean,
    onClose: () => void,
    onFileUpload: (file: File, user: User, competences: Competence[], users?: DiscordUserType[]) => void;
    doc: Doc
}

interface Userr {
    name: string;
    email: string;
}

export const AboutDocumentModal = ({ doc, project, isOpen, onFileUpload, onClose }: Props) => {

    // const project: Project = JSON.parse(localStorage.getItem('project')!)
    const { user } = useFetchUser()

    const [name, setName] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [users, setUsers] = useState<Userr[]>([]);
    const [photoPreview, setPhotoPreview] = useState<string | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [photo, setPhoto] = useState<File | null>(null);
    const handleInputChange = (index: number, event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        const newUsers = [...users];
        newUsers[index] = { ...newUsers[index], [name]: value };
        setUsers(newUsers);
    };
    const [error, setError] = useState('')

    const handleAddUser = () => {
        setUsers([...users, { name: '', email: '' }]);
    };

    const handleRemoveUser = (index: number) => {
        const newUsers = users.filter((_, i) => i !== index);
        setUsers(newUsers);
    };


    const competencesList = [
        'Competence 1',
        'Competence 2',
        'Competence 3',
        'Competence 4',
        'Competence 1',
        'Competence 2',
        'Competence 3',
        'Competence 4',
        'Competence 1',
        'Competence 2',
        'Competence 3',
        'Competence 4',
        'Competence 1',
        'Competence 2',
        'Competence 3',
        'Competence 4'
    ];
    const [selectedCompetences, setSelectedCompetences] = useState<Competence[]>([]);

    const handleCompetenceChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value, checked, name } = event.target;
        const comp: Competence = { id: parseInt(value), name: name };
        setSelectedCompetences((prev) =>
            checked ? [...prev, comp] :
                prev.filter((competence) => competence.id !== parseInt(value))
        );
        // console.log(selectedCompetences)
    };
    const [selectedUsers, setSelectedUsers] = useState<DiscordUserType[]>([]);
    const handleUserChange = (user: DiscordUserType, event: ChangeEvent<HTMLInputElement>) => {
        const { checked, name } = event.target;

        setSelectedUsers((prev) =>
            checked ? [...prev, user] :
                prev.filter((competence) => competence.id !== (user.id))
        );
        // console.log(selectedUsers)
    };


    const handleImageClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const deleteImage = () => {
        setPhotoPreview(null);
        setPhoto(null)
    };

    const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setName(value);

    }
    const hadnleDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = event.target;
        setDescription(value);
    }
    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        if (!name) {
            setError('Name cannot be empty!')
        } else if (!description) {
            setError('Description cannot be empty!')
        }

        const project = {
            name: name,
            creatorId: 'string',
            description: description,
            sysRank: 0,
            competences: selectedCompetences,
            users: users,
            photo: photoPreview
        }
        // console.log(project)
        // handleNewProject(project, team.id)
        // console.log('Submitting form data:', finalFormData)

        // handleNewTeam(finalFormData);
        // Implement the upload functionality here
    };
    const [file, setFile] = useState<File | null>(null);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
    };

    const [tx, setTx] = useState('');
    const [deposited, setDeposited] = useState(false);
    const [deposit, setDeposit] = useState('');
    const [uploading, setUploading] = useState(false);
    const upload = async (file: File, user: User) => {
        // console.log('category')
        // const users: User[] = await getParticipantsWallets(category);

        setUploading(true)

        onFileUpload(file, user, selectedCompetences)
        // setFile(null)
        // setUploading(false)

    }


    if (!isOpen) return null;

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" style={{ height: 'max-content', width: '80%' }} onClick={e => e.stopPropagation()}>
                <label style={{ fontSize: '25px' }}><b>DOCUMENT</b></label>
                {/* {error != '' && <label>{error}</label>} */}
                <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                    <div style={{ background: "#575757", borderRadius: '10px', padding: '10px', maxHeight: '40vh', marginTop: '0px' }}>
                        <label><b>Document name: </b>{doc.name} </label>

                        {/* <img width="50" onClick={handleAddUser} style={{ background: '#bbbbbb', borderRadius: '5px', cursor: 'pointer' }} src={PLUS} /> */}
                        {/* <button type="button" onClick={handleAddUser} style={{ padding: '10px', cursor: 'pointer' }}>Add User</button> */}

                    </div>
                </div>

                <div style={{ height: '100%', width: '100%', display: 'grid', gridTemplateColumns: '5fr 5fr', columnGap: '10px' }}>
                    <div>
                        <div style={{ background: "#575757", borderRadius: '10px', padding: '10px', maxHeight: '40vh', marginTop: '0px' }}>
                            <label><b>Document Members:</b></label>
                            <div style={{ display: 'grid', overflowY: 'scroll', maxHeight: '30vh', gridTemplateColumns: '1fr 1fr 1fr', columnGap: '20px', width: '100%' }}>
                                {doc.editors?.map((user, index) => (
                                    <div key={index} className="user-field" style={{ background: '#bbb', borderRadius: '5px', display: 'flex', padding: '2px', width: '100%', marginTop: '2px', alignItems: 'center', marginBottom: '10px' }}>
                                        <label style={{ flexGrow: 1, color: 'black' }}>#{user.username}</label> {/* Display user name */}

                                    </div>
                                ))}
                            </div>
                            {/* <img width="50" onClick={handleAddUser} style={{ background: '#bbbbbb', borderRadius: '5px', cursor: 'pointer' }} src={PLUS} /> */}
                            {/* <button type="button" onClick={handleAddUser} style={{ padding: '10px', cursor: 'pointer' }}>Add User</button> */}

                        </div>

                    </div>

                    <div style={{ background: "#575757", borderRadius: '10px', padding: '10px' }}>
                        <div>
                            <div>
                                <b><label htmlFor="competences">Competences:</label></b>
                            </div>
                            <div>
                                <div>
                                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', maxHeight: '16vh', padding: '2px', rowGap: '3px', columnGap: '5px', overflowY: 'scroll' }}>
                                        {doc.competences?.map((competence) => (
                                            <div style={{ background: "#bbb", alignItems: 'center', overflowY: 'hidden', overflowX: 'scroll', borderRadius: "5px" }}>
                                                <label style={{ flexGrow: 1, color: 'black' }}>#{competence.name}</label> {/* Display user name */}

                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>


            </div >
        </div >
    );
};
